import React from 'react';
import styled, { keyframes } from 'styled-components';

const Loader = () => {

  const rotate360 = keyframes`
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  `;

  const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh; 
  `;

  const Spinner = styled.div`
	margin: 25%;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 3px solid var(--LIGHTORANGE);
	border-right: 3px solid var(--LIGHTORANGE);
	border-bottom: 3px solid var(--LIGHTORANGE);
	border-left: 5px solid var(--LIGHTORANGE);
	background: transparent;
	width: 130px;
	height: 130px;
	border-radius: 50%;

  @media (max-width: 768px) {
    animation: ${rotate360} 1s linear infinite;
	  transform: translateZ(0);
    border-top: 1px solid var(--LIGHTORANGE);
	  border-right: 1px solid var(--LIGHTORANGE);
	  border-bottom: 1px solid var(--LIGHTORANGE);
	  border-left: 3px solid var(--LIGHTORANGE);
    width: 80px;
    height: 80px;
  }

  @media (min-width: 2560px) {
    animation: ${rotate360} 1s linear infinite;
	  transform: translateZ(0);
    border-top: 5px solid var(--LIGHTORANGE);
	  border-right: 5px solid var(--LIGHTORANGE);
	  border-bottom: 5px solid var(--LIGHTORANGE);
	  border-left: 8px solid var(--LIGHTORANGE);
    width: 250px;
    height: 250px;
  }
`;

  return (
    <LoaderContainer>
      <Spinner />
      <div className="text-xl xl:text-2xl 2xl:text-5xl pt-2">Loading...</div>
    </LoaderContainer>
  )
}

export default Loader;
