import React from "react";

const FloatingInputBox = ({
  fieldName,
  type,
  changeValue,
  handleText,
  star,
}) => {
 
  return (
    <div className="">
      <div className="relative">
        <input
          type={type}
          id={fieldName}
          className="block p-3  w-full text-sm font-bold bg-transparent rounded-lg border-2 border-deepBlue appearance-none text-deepBlue text-bold dark:border-deepBlue dark:focus:border-deepBlue focus:outline-none focus:ring-0 focus:border-deepBlue peer"
          placeholder=" "
          onChange={changeValue}
          autoComplete="off"
        />
        <label
          htmlFor={fieldName}
          className="absolute text-lg text-deepBlue font-bold   rounded-2xl duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-deepBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          {fieldName}
          <span className="ms-1 text-md font-bold text-red-600">*</span>
        </label>
      </div>
      {handleText && handleText !== "" ? (
        <span className=" ms-2 text-md font-bold text-red-600">
          {handleText}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default FloatingInputBox;
