import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { validContactNo, validEmail } from "../../utils/common/Common";

const Label = ({ label, className, value, spSign }) => {
  const [sign, setSign] = useState("");

  useEffect(() => {
    setSign(spSign);
  }, [spSign]);

  const handleMailClick = (e) => {
    e.preventDefault();
    if (validEmail(value)) {
      window.location.href=`mailto:${value}`;
    } else {
      window.location.href=`tel:${value}`;
    }
  };

  return (
    <div>
      <div className={`flex max-sm:flex-col ml-auto ${className}`}>
        <div className="w-1/2">
          <label className="text-navyBlue text-xl">
            <b>{label}</b>
          </label>
        </div>
        <div className="w-1/2 text-warnOrange font-extrabold text-xl">
          {validEmail(value) || validContactNo(value) ? (
            <div
              className="cursor-pointer hover:underline"
              onClick={handleMailClick}
            >
              {value}
            </div>
          ) : (
            <div>
              {value ? value : `-`}
              {sign}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  spSign: PropTypes.any,
};

export default Label;
