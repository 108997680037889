/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";
import InputField from "../../components/InputField/InputField";
import AddFile from "../../components/Models/AddFile";
import Title from "../../components/Title/Title";
import {
  checkEmptySingle,
  validContactNo,
  validEmail,
  validName,
  validateFile,
} from "../../utils/common/Common";
import { GetSingleConnection } from "../../services/GetService";
import {
  PostAllConnection,
  UpdateAllConnection,
} from "../../services/PostService";
import { WelcomeBackground1 } from "../../utils/images/Images";
import Button from "../../components/Button/Button";
import { ErrorToast, SuccessToast } from "../../components/Toast/ToastCom";

const AddConnection = () => {
  const navigateForm = useNavigate();
  const navigate = useNavigate();
  const userId = useParams();

  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const fieldName = {
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    mobileNo: "Mobile No:",
    email: "Email",
    linkedin: "linkedin",
    instagram: "Instagram",
    facebook: "Facebook",
    twitter: "Twitter",
    company: "Company",
    designation: "Designation",
    location: "Location",
    city: "City",
    state: "State",
    nationality: "Nationality",
    age: "Age",
    hobby: "Hobby",
    notePersonal: "Note Personal",
    photo: "Photo",
    referredBy: "Referred By",
  };

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNo: "",
    email: "",
    linkedin: "",
    instagram: "",
    facebook: "",
    twitter: "",
    company: "",
    designation: "",
    location: "",
    city: "",
    state: "",
    nationality: "",
    age: "",
    hobby: "",
    notePersonal: "",
    photo: "hello",
    referredBy: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    // middleName: "",
    lastName: "",
    mobileNo: "",
    email: "",
    company: "",
    photo: "",
  });

  const validation = (from, value) => {
    const errors = { ...validationErrors };
    if (checkEmptySingle(value)) {
      switch (from) {
        case "firstName":
          errors[from] = validName(value)
            ? ""
            : "First Name field is required and must be alphabetic";
          break;
        // case "middleName":
        //   errors[from] = validName(value)
        //     ? ""
        //     : "Middle Name field is required and must be alphabetic";
        //   break;
        case "lastName":
          errors[from] = validName(value)
            ? ""
            : "Last Name field is required and must be alphabetic";
          break;
        case "mobileNo":
          errors[from] = validContactNo(value)
            ? ""
            : "Mobile Number must be a valid phone number";
          break;
        case "email":
          errors[from] = validEmail(value)
            ? ""
            : "Email must be a valid email address";
          break;
        case "company":
          errors[from] = validName(value) ? "" : "Company Name Required";
          break;

        case "photo":
          errors[from] = validName(value) ? "" : "Photo is Required";
          break;

        //   case "mainSkill":
        //     errors[from] = validName(value)
        //       ? ""
        //       : "Main Skill Field can not be Number";
        //     break;
      }
    }else {
      errors[from] = "";
    }
    // console.log(errors);
    setValidationErrors((prevErrors) => ({ ...prevErrors, ...errors }));

    if (Object.values(errors).some((error) => error)) {
      return false;
    }

    return true;
  };

  const getEditData = async () => {
    try {
      const res = await GetSingleConnection(userId.id);
      const connectionData = res?.data?.Data;
      setFormData({
        firstName: connectionData.firstName,
        middleName: connectionData.middleName,
        lastName: connectionData.lastName,
        mobileNo: connectionData.mobileNo,
        email: connectionData.email,
        linkedin: connectionData.linkedin,
        instagram: connectionData.instagram,
        facebook: connectionData.facebook,
        twitter: connectionData.twitter,
        company: connectionData.company,
        designation: connectionData.designation,
        location: connectionData.location,
        city: connectionData.city,
        state: connectionData.state,
        nationality: connectionData.nationality,
        age: connectionData.age,
        hobby: connectionData.hobby,
        notePersonal: connectionData.notePersonal,
        photo: connectionData.photo,
        referredBy: connectionData.referredBy,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (userId.id !== undefined) {
      getEditData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleDisable();
    // eslint-disable-next-line no-sparse-arrays, react-hooks/exhaustive-deps
  }, [
    formData.firstName,
    // formData.middleName,
    formData.lastName,
    formData.mobileNo,
    formData.email,
    formData.company,
    ,
    formData.photo,
    formData.mainSkill,
  ]);

  const handleInputChange = (fieldName, event) => {
    const { value } = event;
    setFormData({ ...formData, [fieldName]: value });
    validation(fieldName, value);
  };

  const handleFileChange = (file) => {
    if (validateFile(file, "image/jpeg", 1)) {
      setFormData({ ...formData, photo: file });
      setValidationErrors({ ...validationErrors, photo: "" });
      handleShow();
    } else {
      setValidationErrors({
        ...validationErrors,
        photo: "File must be Jpeg and less than 1mb.",
      });
    }
  };

  const handleType = (key) => {
    if (key === "photo") {
      return "button";
    } else {
      return "text";
    }
  };

  const handleDisable = () => {
    if (
      !validName(formData.firstName) === true ||
      // !validName(formData.middleName) === true ||
      !validName(formData.lastName) === true ||
      !validContactNo(formData.mobileNo) === true ||
      !validEmail(formData.email) === true ||
      !validName(formData.company) === true ||
      !validName(formData.mainSkill) === true
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDataObject = new FormData();
    for (const key in formData) {
      formDataObject.append(key, formData[key]);
    }
    if (userId.id === undefined) {
      try {
        const res = await PostAllConnection(formDataObject);
        if (res?.data?.Status === 201 || res?.data?.Status === 200) {
          navigateForm("/connection");
          SuccessToast(res?.data?.Message)
        } else {
          ErrorToast(res?.data?.Message)
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const res = await UpdateAllConnection(userId.id, formDataObject);
        if (res?.data?.Status === 201 || res?.data?.Status === 200) {
          navigateForm("/connection");
        } else {
          ErrorToast(res?.data?.Message);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleShow = () => setShow((prevShow) => !prevShow);
  return (
    <>
      <div
        className="flex justify-center relative"
        style={{
          backgroundImage: `url(${WelcomeBackground1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {show && (
          <AddFile
            maxLimit={"Image Must Be Less Than : 1MB"}
            title={"Upload Image"}
            supportedFile={"Supported Image : jpeg"}
            onFileChange={handleFileChange}
            cancel={handleShow}
            setErr={validationErrors.photo}
          />
        )}
        <div>
          <div className="flex items-center cursor-pointer">
            <BackButton onClick={"/connection"} />
            <div className="pl-4 py-10">
              <Title
                text={
                  userId.id !== undefined ? "Edit Connection" : "Add Connection"
                }
              />
            </div>
          </div>
          <div className="border-t-4 py-8 border-warnOrange  pt-14">
            <div className="bg-lightGry border-solid border-2 shadowform border-warnOrange mb-20 max-[565px]:w-screen ">
              <div className=" px-[99px] py-8 max-sm:px-9 ">
                <div className="w-[700px] max-lg:w-auto ">
                  <form
                    onSubmit={handleSubmit}
                    className=" max-[475px]:flex max-[475px]:flex-col max-[475px]:items-center"
                  >
                    {Object.keys(formData).map((key) => (
                      <InputField
                        key={key}
                        id={key}
                        name={key}
                        className="mb-8"
                        type={handleType(key)}
                        label={fieldName[key] || ""}
                        ml="auto"
                        value={
                          key === "photo"
                            ? formData.photo === null
                              ? ""
                              : formData?.photo?.name
                            : formData[key]
                        }
                        onClick={key === "photo" ? handleShow : undefined}
                        onChange={(e) => handleInputChange(key, e.target)}
                        error={validationErrors[key]}
                      />
                    ))}

                    <div>
                      <div className="text-red-600 font-bold italic text-xs pb-3">
                        * Field Required for submit.
                      </div>
                      <div className="flex gap-2 items-center">
                        <Button
                          text={"Back"}
                          className="bg-warnOrange text-white font-bold text-2xl px-8 py-2 rounded-lg cursor-pointer"
                          onClick={() => navigate(-1)}
                          type={"button"}
                        />
                        <button
                          type="submit"
                          className={`bg-warnOrange uppercase ${!disabled ? "bg-warnOrange" : "bg-gray-200"
                            } text-white font-bold text-2xl px-8 py-2  ${!disabled ? "cursor-pointer" : "cursor-default"
                            } rounded-lg`}
                          disabled={disabled}
                        >
                          {userId.id ? "Save" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddConnection;
