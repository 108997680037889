import { APPLICANT_DATA_URL, CONNECTION_URL, RECRUITER } from "../constant/Api";
import { get } from "./ApiMethods";

/*
    -> "Set up the API methods in this format, please."
            example: 
                export const APIMethodName + AnyName + API = async(params) => {
                    return methodName(URL_NAME, params)
                }
*/

// USERS ALL APIS METHODS
export const GetSingleUsers = async (id) => {
  return get(RECRUITER + id);
};

export const GetSingleApplicant = async (id) => {
  return get(APPLICANT_DATA_URL + "/" + id);
};

//Connection Related Api Function
export const GetSingleConnection = async (id) => {
  return get(CONNECTION_URL + id);
};
