import axios from "axios";
import { ErrorToast, SuccessToast } from "../components/Toast/ToastCom";
import Cookies from "js-cookie";

const getHeaderInfo = async function () {
  return {
    headers: {
      "content-type": "application/json",
    },
  };
};

const handleResponse = (response) => {
  console.log('ksdjhfksdfksjdfsjd',response);
  if(response?.data?.Status === 200 
    && response?.data?.Data?.AccessToken !== undefined ){
      Cookies.set("accessToken", (response?.data?.Data?.AccessToken));
      Cookies.set("refreshToken", (response?.data?.Data?.RefreshToken));
    }
      
  return response;
};

export const get = async function (url, params = {}) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.get(url, { ...header, params });
    return handleResponse(resp);
  } catch (err) {
    ErrorToast(err?.response?.data?.error?.message);
    throw handleResponse(err?.response?.data?.error?.message);
  }
};

export const post = async function (url, params) {
  try {
    let response = await axios.post(url, params);
    // if(response?.data?.Status === 201 || response?.data?.Status === 200){
    //   SuccessToast(response?.data?.Message)
    // }else{
    //   ErrorToast(response?.data?.Message)
    // }
    return handleResponse(response);
  } catch (err) {
    ErrorToast(err?.response?.data?.error?.message);
    return handleResponse(err);
  }
};

export const patch = async function (url, params) {
  try {
    let response = await axios.patch(url, params);
    // if(response?.data?.status !== 200){   
    //   ErrorToast(response?.data?.Message)
    // }
    // SuccessToast(response?.data?.Message);
    return handleResponse(response);
  } catch (err) {
    ErrorToast(err?.response?.data?.error?.message);
    throw handleResponse(err?.response?.data?.error?.message);
  }
};

export const deleteApi = async function (url, params = {}) {
  let header = await getHeaderInfo();
  try {
    let response = await axios.delete(url, { header, params });
    SuccessToast(response?.data?.Message);
    return handleResponse(response);
  }  catch (err) {
    ErrorToast(err?.response?.data?.error?.message);
    throw handleResponse(err?.response?.data?.error?.message);
  }
};

axios.interceptors.request.use(
  (config) => {
    const access = Cookies.get("accessToken");

    config.headers["Authorization"] = "Bearer " + access;

    return config;
  },

  (error) => {
    Promise.reject(error);
  }
);
