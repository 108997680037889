import React from "react";

const EditLabel = ({ label }) => {
  return (
    <div className="absolute top-5 xl:top-10 left-0">
      <span className="bg-deepBlue text-white font-extrabold text-24 py-3 px-5 first-letter:p-1 shadow-md rounded-r-xl">
        {label}
      </span>
    </div>
  );
};
export default EditLabel;
