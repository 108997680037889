import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const LoginCookie = Cookies.get('loginCookieData', { encode: false })

let LoginDataRedux;
if (LoginCookie) {
    const decodedCookie = decodeURIComponent(LoginCookie);
    const userLogData = LoginCookie !== undefined && JSON.parse(decodedCookie);
    LoginDataRedux = userLogData;
}

const initialState = {
    loginData: LoginDataRedux || null,
    IsLogin: !!LoginDataRedux?.access || false,
    profileData: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            return {
                ...state,
                IsLogin: true,
                loginData: action?.payload,
            }
        },
        logout: (state) => {
            return {
                ...state,
                IsLogin: false,
                loginData: null
            }
        },
        profiledata: (state,action) => {
            return {
                ...state,
                profileData: action?.payload
            }
        }
    }
})

export const { login, logout } = authSlice?.actions;
export default authSlice?.reducer;
