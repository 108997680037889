//if you want to valdate whole object is empty or not so use it.
export const checkEmpty = (data) => {
  for (const key in data) {
    if (data[key].trim() === "" ) {
      return false;
      // console.log(false)
    }
  }
  return true;
  // console.log('true')
};

export const checkEmptySingle = (value) => {
  if(value.trim() === "")
  {
    return false
  }else{
    return true
  }
}

export const validEmail = (value) => {
  const regex = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  return regex.test(value);
};

// export const validContactNo = (value) => {
//   const phoneNumberRegex = /^[^A-Za-z]{8,18 }$/;
//   return phoneNumberRegex.test(value);
// };

export const validContactNo = (value) => {
  const phoneNumberRegex = /^\d{8,16}$/;
  return phoneNumberRegex.test(value);
};



export const validNo = (value) => {
  const numericRegex = /^\d+$/;
  return numericRegex.test(value);
};

export const validExperience = (value) => {
  const numericRegex = /^\d{1,2}$/;
  return numericRegex.test(value);
};

export const validName = (value) => {
  const nameRegex = /^[a-zA-Z\s'-]+$/;
  return nameRegex.test(value);
};

export const numberValidaData = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const validatePassword = (password) => {
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /[0-9]/;
  const symbolRegex = /[^A-Za-z0-9]/;

  return (
    password &&
    password.length >= 6 &&
    uppercaseRegex.test(password) &&
    lowercaseRegex.test(password) &&
    numberRegex.test(password) &&
    symbolRegex.test(password)
  );
};

export const validateFile = (file, type, size) => {
  if (file !== undefined) {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    if (file.type === type && fileSizeInMB <= size) {
      return true;
    } else {
      return false;
    }
  }
};

export const formateCurrency = (number) => {
  if (typeof number !== "number") {
    return "Invalid number";
  }
  const formattedRupees = number?.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
  return formattedRupees;
};
