import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import { login } from "../../redux/Slice/AuthSlice";
import { UserLogin } from "../../services/PostService";
import Button from "../Button/Button";
import FloatingInputBox from "../InputField/FloatingInput";

const Form = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    // const uppercaseRegex = /[A-Z]/;
    // const lowercaseRegex = /[a-z]/;
    // const numberRegex = /[0-9]/;
    // const symbolRegex = /[^A-Za-z0-9]/;

    // return (
    //   password.length >= 6 &&
    //   uppercaseRegex.test(password) &&
    //   lowercaseRegex.test(password) &&
    //   numberRegex.test(password) &&
    //   symbolRegex.test(password)
    // );
    return password && password.trim() !== "";
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (!validatePassword(e.target.value)) {
      setPasswordError("Password is Required for Login");
    } else {
      setPasswordError("");
    }
  };


  useEffect(() => {
    const handleDisabled = () => {
      // if (validateEmail(email) && validatePassword(password)) {
      if (validateEmail(email) && validatePassword(password)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };

    handleDisabled();
  }, [email, password]);

  const titleMessageError = {
    icon: "error",
    title: "Please Check Your Credentials...",
    text: "User Does Not Exists!",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (validateEmail(email) && validatePassword(password)) {
    if (validateEmail(email) && validatePassword(password)) {
      try {
        const userLoginData = { email: email, password: password };
        const res = await UserLogin(userLoginData);
        if (res?.data?.Status === 200) {
          Swal.fire(res?.data?.Message);
          const setCookiesData = {
            refresh: res?.data?.Data?.RefreshTOken,
            access: res?.data?.Data?.AccessToken,
            userDetails: {
              id: res?.data?.Data?.RecruiterData?._id,
              name: res?.data?.Data?.RecruiterData?.name,
              email: res?.data?.Data?.RecruiterData?.email,
              image: res.data?.Data?.RecruiterData?.image,
              role: res?.data?.Data?.RecruiterData?.role,
            },
          };
          Cookies.set("loginCookieData", JSON.stringify(setCookiesData));
          dispatch(login(setCookiesData));
          
            navigate("/dashboard");
          
        } else {
          navigate("/login");
          Swal.fire({...titleMessageError, text: res?.data?.Message});
        }
      } catch (res) {
        Swal.fire(titleMessageError);
        // navigate('/login')
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-3 w-full">
      <div>
        <FloatingInputBox
          fieldName={"Email"}
          type={"email"}
          changeValue={handleEmailChange}
          handleText={emailError}
        />
      </div>
      <div className="mt-3 relative">
        <FloatingInputBox
          fieldName={"Password"}
          type={`${showPassword ? "text" : "password"}`}
          changeValue={handlePasswordChange}
          handleText={passwordError}
        />
        <div
          className="absolute right-2 top-5 cursor-pointer"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>

      <div className="text-center mt-4">
        <Button
          text={"Login"}
          type={"submit"}
          disabled={disabled}
          className={`${
            disabled
              ? "bg-[rgba(241,151,23,0.6)] hover:bg-[rgba(241,151,23,0.6)]"
              : "bg-warnOrange"
          } text-white px-10 py-2 lg:py-4 text-xl  focus-visible:outline-none outline-0 uppercase rounded 2xl:rounded-2xl font-extrabold hover:bg-[rgba(241,151,23,0.8)] border-none tracking-wider shadow-md`}
        />
      </div>

      {/* this is forget email code so please don't delete it  */}

      {/* <div className="text-center mt-4">
        <button
          type="button"
          onClick={handleForgotPassword}
          className="text-deepBlue underline hover:text-deepBlue-dark focus:outline-none"
        >
          Forgot Password?
        </button>
      </div> */}
    </form>
  );
};

export default Form;
