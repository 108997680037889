import { Modal } from "react-bootstrap";
import React from "react";
import Button from "../../components/Button/Button";

const DeleteDeveloper = ({ show, onCancel, onDelete, text }) => {
  return (
    <Modal show={show} centered>
      <Modal.Body className="font-bold text-xl text-center pt-4">
        Are you sure, You want to Delete {text} ?
      </Modal.Body>

      <Modal.Footer className="gap-3 justify-content-center border-0 pb-3">
        <Button
          className="px-3 py-2 text-black font-bold bg-gray-100 border-warnOrange hover:bg-white rounded-lg border-1 text-lg focus:z-10"
          onClick={() => onCancel()}
          text={"Cancel"}
        ></Button>

        <Button
          text={"Delete"}
          onClick={() => onDelete()}
          className="px-4 py-2 font-bold bg-warnOrange text-white rounded-lg border border-warnOrange text-lg focus:z-10"
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDeveloper;
