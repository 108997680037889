import React from 'react';
import { HiOutlineDocumentArrowUp } from "react-icons/hi2";
import { MdCancel } from "react-icons/md";

const AddFile = ({ onFileChange, cancel,setErr,title,supportedFile,maxLimit}) => {

    // const [isDragOver, setDragOver] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        // setDragOver(true);
    };

    const handleDragLeave = () => {
        // setDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        // setDragOver(false);

        const files = e.dataTransfer.files;
        onFileChange(files[0]);
        
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        onFileChange(files[0]);
    };

    return (
        <div className='fixed top-1/4 z-20'>
            <div className='max-lg:w-screen'>
                <div className='absolute end-5 top-5' onClick={cancel}><MdCancel className='hover:cursor-pointer' size={30} /></div>
                <div className="flex flex-col items-center justify-center p-10 w-[1000px] max-lg:w-screen rounded-lg border-2 bg-powderBlue border-powderBlue shadow-lg">
                    <div className='w-full '>
                        <div className='font-bold w-full justify-start text-2xl pb-2 max-[]:'>{title}</div>
                        <label for="dropzone-file" className="flex flex-col items-center justify-center w-full h-72 border-2 border-dashed rounded-lg border-mediumGray" onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}>
                            <div className="flex flex-col items-center justify-center pt-5 pb-6 ">
                                <HiOutlineDocumentArrowUp size={"70px"} className='mb-2' />
                                <p className="mb-2 text-lg font-bold text-center">Drag and Drop files here</p>
                                <div className='text-base pb-3 text-center'>{supportedFile}</div>
                                <div className='bg-warnOrange text-white px-3 py-1 rounded-md'><input id='dropzone-file' type="file" className="hidden" onChange={handleFileChange} />Choose File</div>
                                <div>{setErr}</div>
                                <div className='text-lg mt-3 text-center '>{maxLimit}</div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddFile;
