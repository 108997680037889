import React, { useEffect, useState } from "react";
import { GetAllUsers } from "../../services/PostService";
import defaultProfileImage from "../../utils/images/assets/sidebarNavbar/dashboardScreen/ProfileDefault.png";
import "./Recruiter.css";
import SocialMedia from "../../components/Socialmedia/SocialMedia";

const Recruiters = () => {
  const [recruiters, setRecruiters] = useState([]);

  const getRecruiter = async () => {
    const res = await GetAllUsers();
    setRecruiters(res?.data?.Data);
  };

  useEffect(() => {
    getRecruiter();
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 my-4 2xl:grid-cols-4 gap-4">
      {recruiters?.map((item, index) => (
        <div
          key={index}
          className="shadowRecruiter bg-white rounded-xl py-5 md:p-5"
        >
          <div className="flex flex-col justify-center items-center gap-4">
            <div className="w-40 h-40 mx-auto flex justify-center border-warnOrange border-3 items-center rounded-full overflow-hidden">
              <img
                src={item.image ? item.image : defaultProfileImage}
                alt="Not Found"
                className="h-40 w-40 object-cover rounded-full"
                onError={(e) => (e.target.src = defaultProfileImage)}
              />
            </div>
            <h2 className="text-center text-deepBlue text-2xl xl:text-4xl font-bold">
              {item.name}
            </h2>
            <div className="flex justify-center items-center gap-4 my-3">
              <SocialMedia Data={item} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Recruiters;
