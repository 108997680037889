import React from "react";

const Title = ({ text, className }) => {
  return (
    <div>
      <h2
        className={`font-extrabold text-5xl max-sm:text-2xl md:text-3xl 2xl:text-5xl ${className}`}
      >
        {text}
      </h2>
    </div>
  );
};

export default Title;
