import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";
import InputField from "../../components/InputField/InputField";
import AddFile from "../../components/Models/AddFile";
import Title from "../../components/Title/Title";
import {
  checkEmptySingle,
  validContactNo,
  validEmail,
  validName,
  validNo,
  validateFile,
  validExperience,
} from "../../utils/common/Common";
import { DashboardID } from "../../redux/Selector/Selector";
import { GetSingleApplicant } from "../../services/GetService";
import { GetSingleUsers } from "../../services/GetService";
import {
  PostAllApplicants,
  UpdateAllApplicant,
} from "../../services/PostService";
import "./AddDeveloperForm.css";
import { WelcomeBackground1 } from "../../utils/images/Images";
import { ErrorToast, SuccessToast } from "../../components/Toast/ToastCom";
import Button from "../../components/Button/Button";

const AddDeveloperForm = () => {
  const navigateForm = useNavigate();
  const Login_ID = useSelector(DashboardID.getdashboardId());
  const navigate = useNavigate();
  const applicantId = useParams();
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const fieldName = {
    applicantName: "Applicant Name",
    contactNumber: "Contact Number",
    email: "Email",
    totalExperience: "Total Experience",
    relevantExperience: "Relevant Experience",
    currentCTC: "Current CTC",
    expectedCTC: "Expected CTC",
    noticePeriod: "Notice Period",
    currentLocation: "Current Location",
    preferedLocation: "Prefer Location",
    companyName: "Company Name",
    resume: "Resume",
    mainSkill: "Main Skill",
    otherSkill: "Other Skill",
  };

  const [recruiter, setRecruiter] = useState({
    recruiterName: "",
    recruiterId: Login_ID,
  });

  const [formData, setFormData] = useState({
    applicantName: "",
    contactNumber: "",
    email: "",
    recruiterId: recruiter?.recruiterId,
    totalExperience: "",
    relevantExperience: "",
    currentCTC: "",
    expectedCTC: "",
    noticePeriod: 1,
    currentLocation: "",
    preferedLocation: "",
    companyName: "",
    resume: null,
    mainSkill: "",
    otherSkill: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    applicantName: "",
    contactNumber: "",
    email: "",
    currentLocation: "",
    preferLocation: "",
    resume: "",
    mainSkill: "",
  });

  const getprofileData = async (id) => {
    try {
      if (id) {
        const res = await GetSingleUsers(id);
        setRecruiter({ recruiterName: res?.data?.Data?.name });
      }
    } catch (error) {
      console.log("something went wrong:", error);
    }
  };

  const getEditData = async () => {
    try {
      const res = await GetSingleApplicant(applicantId?.id);
      const applicantData = res?.data?.Data;
      // const resRec = await getprofileData(applicantData?.recruiterId);
      setRecruiter({ recruiterName: applicantData?.recruiterId.name });

      setFormData({
        applicantName: applicantData?.applicantName,
        contactNumber: applicantData?.contactNumber,
        email: applicantData?.email,
        recruiterId: applicantData?.recruiterId._id,
        totalExperience: applicantData?.totalExperience,
        relevantExperience: applicantData?.relevantExperience,
        currentCTC: applicantData?.currentCTC,
        expectedCTC: applicantData?.expectedCTC,
        noticePeriod: applicantData?.noticePeriod,
        currentLocation: applicantData?.currentLocation,
        preferedLocation: applicantData?.preferedLocation,
        companyName: applicantData?.companyName,
        resume: applicantData?.resume,
        mainSkill: applicantData?.mainSkill,
        otherSkill: applicantData?.otherSkill,
      });
    } catch {
      console.log("something Wrong");
    }
  };

  const validation = (from, value) => {
    const errors = { ...validationErrors };
    if (checkEmptySingle(value)) {
      switch (from) {
        case "applicantName":
          errors[from] = validName(value)
            ? ""
            : "Applicant Name field is required and must be alphabetic";
          break;
        case "contactNumber":
          errors[from] = validContactNo(value)
            ? ""
            : "Contact Number must be a valid phone number";
          break;
        case "email":
          errors[from] = validEmail(value)
            ? ""
            : "Email must be a valid email address";
          break;
        case "currentLocation":
          errors[from] = validName(value)
            ? ""
            : "Current Location Field Required and Not be number";
          break;

        case "preferLocation":
          errors[from] = validName(value)
            ? ""
            : "Perfer Location is Required and can not be number";
          break;

        case "mainSkill":
          errors[from] = validName(value)
            ? ""
            : "Main Skill Field can not be Number or empty";
          break;

        case "totalExperience" :
          errors[from] = validExperience(value)
            ? ""
            : "Total experience must be a number & must be a valid";
          break;

        case "expectedCTC" :
          errors[from] = validNo(value)
            ? ""
            : "Expected CTC must be a number";
          break;

        case "relevantExperience"  :
          errors[from] = validExperience(value)
            ? ""
            : "Relevant experience must be a number & must be valid";
          break;

        case "currentCTC" :
          errors[from] = validNo(value)
            ? ""
            : "Current CTC must be a number";
          break;
      }
    } else {
      errors[from] = "";
    }
    setValidationErrors((prevErrors) => ({ ...prevErrors, ...errors }));
    if (Object.values(errors).some((error) => error)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (applicantId.id !== undefined) {
      getEditData();
      handleDisable();
    } else {
      getprofileData(Login_ID);
    }
  }, []);

  useEffect(() => {
    handleDisable();
  }, [
    formData.applicantName,
    formData.contactNumber,
    formData.email,
    formData.currentLocation,
    // formData.preferedLocation,
    formData.resume,
    formData.mainSkill,
  ]);

  const handleInputChange = (fieldName, event) => {
    const { value } = event;
    setFormData({ ...formData, [fieldName]: value });
    validation(fieldName, value);
  };

  const handleFileChange = (file) => {
    if (validateFile(file, "application/pdf", 5)) {
      setFormData({ ...formData, resume: file });
      setValidationErrors({ ...validationErrors, resume: "" });
      handleShow();
    } else {
      setValidationErrors({
        ...validationErrors,
        resume: "File must be pdf and less than 5mb.",
      });
    }
  };

  const handleType = (key) => {
    if (key === "resume") {
      return "button";
    // } else if (
    //   key === "totalExperience" ||
    //   key === "expectedCTC" ||
    //   key === "relevantExperience"
    //   key === "currentCTC"
    // ) {
    //   return "number";
    } else if (key === "noticePeriod") {
      return "dropDownSearch";
    } else {
      return "text";
    }
  };

  const handleDisable = () => {
    if (
      validName(formData?.applicantName) === true &&
      validContactNo(formData?.contactNumber) === true &&
      validEmail(formData?.email) === true &&
      validName(formData?.currentLocation) === true &&
      // validName(formData?.preferedLocation) === true &&
      formData?.resume !== null &&
      validName(formData?.mainSkill) === true
    ) {
      setDisabled(false);
      console.log("false")
    } else {
      console.log("true")
      setDisabled(true);
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDataObject = new FormData();
    for (const key in formData) {
      formDataObject.append(key, formData[key]);
    }

    if (disabled === false) {
      if (applicantId?.id === undefined) {
        try {
          const res = await PostAllApplicants(formDataObject);
          console.log("------------------------------------------------------->", res)
          if (res?.data?.Status === 201) {
            navigateForm("/programmers");
            SuccessToast(res?.data?.Message);
          }else{
            ErrorToast(res?.data?.Message);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const res = await UpdateAllApplicant(applicantId.id, formDataObject);
          if (res?.status === 200) {
            navigateForm("/programmers");
            SuccessToast(res?.data?.Message);
          } else {
            ErrorToast(res?.data?.Message);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const numberValidaData = (e) => {
    if (e.key === "Backspace" || e.key === "Enter" || e.key === "Tab") {
      return;
    }

    if (e.key === "-" && e.target.value.includes("-")) {
      e.preventDefault();
      return;
    }
    if (
      e.key === "-" &&
      (e.target.value === "" || e.target.selectionStart !== 0)
    ) {
      e.preventDefault();
      return;
    }

    // Allow digits
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleShow = () => setShow((prevShow) => !prevShow);

  return (
    <>
      <div
        className="flex justify-center relative"
        style={{
          backgroundImage: `url(${WelcomeBackground1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {show && (
          <AddFile
            maxLimit={"File Must Be Less Than : 5MB"}
            title={"Upload File"}
            supportedFile={"Supported Image : pdf"}
            onFileChange={handleFileChange}
            cancel={handleShow}
            setErr={validationErrors.resume}
          />
        )}
        <div>
          <div className="flex items-center cursor-pointer mx-1 mt-5 mb-3">
            <BackButton onClick={"/programmers"} />
            <div className="pl-4">
              {applicantId.id !== undefined ? (
                <Title
                  text={"Edit Applicant"}
                  className="py-10 md:text-4xl 2xl:text-5xl"
                />
              ) : (
                <Title
                  text={"Add Applicant"}
                  className="py-10 md:text-4xl 2xl:text-5xl"
                />
              )}
            </div>
          </div>
          <div className="border-t-4 py-8 border-warnOrange  pt-14">
            <div className="bg-lightGry border-solid border-2 shadowform border-warnOrange mb-20 max-[565px]:w-screen ">
              <div className=" px-[99px] py-8 max-sm:px-9 ">
                <div className="w-[700px] max-lg:w-auto ">
                  <form
                    onSubmit={handleSubmit}
                    className=" max-[475px]:flex max-[475px]:flex-col max-[475px]:items-center"
                  >
                    {Object.keys(formData).map((key) =>
                      key !== "recruiterId" ? (
                        <InputField
                          key={key}
                          id={key}
                          name={key}
                          className="mb-8 textInput"
                          type={handleType(key)}
                          label={fieldName[key] || ""}
                          ml="auto"
                          value={
                            key === "resume"
                              ? applicantId !== undefined
                                ? formData.resume
                                : formData.resume.name
                              : formData[key]
                          }
                          onClick={key === "resume" ? handleShow : undefined}
                          onChange={(e) => handleInputChange(key, e.target)}
                          onKeyDown={numberValidaData}
                          error={validationErrors[key]}
                        />
                      ) : (
                        <InputField
                          key="recruiterName"
                          id={"recruiterName"}
                          className={"mb-8"}
                          type={"text"}
                          label={"Recruiter Name"}
                          ml={"auto"}
                          value={recruiter.recruiterName}
                          disabled={true}
                        />
                      )
                    )}
                    <div>
                      <div className="text-red-600 font-bold italic text-xs pb-3">
                        * Field Required for submit.
                      </div>
                      <div className="flex gap-2 items-center">
                        <Button
                          text={"Back"}
                          type={"button"}
                          className="bg-warnOrange text-white font-bold text-2xl px-8 py-2 rounded-lg cursor-pointer"
                          onClick={() => navigate(-1)}
                        />
                        <button
                          type="submit"
                          className={` uppercase ${!disabled ? "bg-warnOrange" : "bg-gray-300"
                            } text-white font-bold text-2xl px-8 py-2  ${!disabled ? "cursor-pointer" : "cursor-default"
                            } rounded-lg`}
                          disabled={disabled}
                        >
                          {applicantId.id ? "save" : "submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDeveloperForm;





// import React, { useCallback, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import BackButton from "../../components/BackButton/BackButton";
// import InputField from "../../components/InputField/InputField";
// import AddFile from "../../components/Models/AddFile";
// import Title from "../../components/Title/Title";
// import {
//   checkEmptySingle,
//   validContactNo,
//   validEmail,
//   validName,
//   validateFile,
// } from "../../utils/common/Common";
// import { DashboardID } from "../../redux/Selector/Selector";
// import { GetSingleApplicant } from "../../services/GetService";
// import { GetSingleUsers } from "../../services/GetService";
// import {
//   PostAllApplicants,
//   UpdateAllApplicant,
// } from "../../services/PostService";
// import "./AddDeveloperForm.css";
// import { WelcomeBackground1 } from "../../utils/images/Images";
// import { ErrorToast, SuccessToast } from "../../components/Toast/ToastCom";
// import Button from "../../components/Button/Button";

// const AddDeveloperForm = () => {
//   const navigateForm = useNavigate();
//   const Login_ID = useSelector(DashboardID.getdashboardId());
//   const navigate = useNavigate();
//   const applicantId = useParams();
//   const [show, setShow] = useState(false);
//   const [disabled, setDisabled] = useState(true);

//   const fieldName = {
//     applicantName: "Applicant Name",
//     contactNumber: "Contact Number",
//     email: "Email",
//     totalExperience: "Total Experience",
//     relevantExperience: "Relevant Experience",
//     currentCTC: "Current CTC",
//     expectedCTC: "Expected CTC",
//     noticePeriod: "Notice Period",
//     currentLocation: "Current Location",
//     preferedLocation: "Prefer Location",
//     companyName: "Company Name",
//     resume: "Resume",
//     mainSkill: "Main Skill",
//     otherSkill: "Other Skill",
//   };

//   const [recruiter, setRecruiter] = useState({
//     recruiterName: "",
//     recruiterId: Login_ID,
//   });

//   const [formData, setFormData] = useState({
//     applicantName: "",
//     contactNumber: "",
//     email: "",
//     recruiterId: recruiter?.recruiterId,
//     totalExperience: "",
//     relevantExperience: "",
//     currentCTC: "",
//     expectedCTC: "",
//     noticePeriod: "",
//     currentLocation: "",
//     preferedLocation: "",
//     companyName: "",
//     resume: null,
//     mainSkill: "",
//     otherSkill: "",
//   });

//   const [validationErrors, setValidationErrors] = useState({
//     applicantName: "",
//     contactNumber: "",
//     email: "",
//     currentLocation: "",
//     preferLocation: "",
//     resume: "",
//     mainSkill: "",
//   });

//   const getprofileData = async (id) => {
//     try {
//       if (id) {
//         const res = await GetSingleUsers(id);
//         setRecruiter({ recruiterName: res?.data?.Data?.name });
//       }
//     } catch (error) {
//       console.log("something went wrong:", error);
//     }
//   };

//   const getEditData = useCallback(async () => {
//     try {
//       const res = await GetSingleApplicant(applicantId?.id);
//       const applicantData = res?.data?.Data;
//       setRecruiter({ recruiterName: applicantData?.recruiterId.name });

//       setFormData({
//         applicantName: applicantData?.applicantName,
//         contactNumber: applicantData?.contactNumber,
//         email: applicantData?.email,
//         recruiterId: applicantData?.recruiterId._id,
//         totalExperience: applicantData?.totalExperience,
//         relevantExperience: applicantData?.relevantExperience,
//         currentCTC: applicantData?.currentCTC,
//         expectedCTC: applicantData?.expectedCTC,
//         noticePeriod: applicantData?.noticePeriod,
//         currentLocation: applicantData?.currentLocation,
//         preferedLocation: applicantData?.preferedLocation,
//         companyName: applicantData?.companyName,
//         resume: applicantData?.resume,
//         mainSkill: applicantData?.mainSkill,
//         otherSkill: applicantData?.otherSkill,
//       });
//     } catch {
//       console.log("something Wrong");
//     }
//   }, [applicantId?.id]);

//   const handleDisable = useCallback(() => {
//     if (
//       validName(formData.applicantName) === true &&
//       validContactNo(formData.contactNumber) === true &&
//       validEmail(formData.email) === true &&
//       validName(formData.currentLocation) === true &&
//       validName(formData.preferLocation) === true &&
//       formData.resume !== null &&
//       validName(formData.mainSkill) === true
//     ) {
//       setDisabled(false);
//     } else {
//       setDisabled(true);
//     }
//   }, [formData]);

//   const validation = (from, value) => {
//     const errors = { ...validationErrors };
//     if (checkEmptySingle(value)) {
//       switch (from) {
//         case "applicantName":
//           errors[from] = validName(value)
//             ? ""
//             : "Applicant Name field is required and must be alphabetic";
//           break;
//         case "contactNumber":
//           errors[from] = validContactNo(value)
//             ? ""
//             : "Contact Number must be a valid phone number";
//           break;
//         case "email":
//           errors[from] = validEmail(value)
//             ? ""
//             : "Email must be a valid email address";
//           break;
//         case "currentLocation":
//           errors[from] = validName(value)
//             ? ""
//             : "Current Location Field Required";
//           break;

//         case "preferLocation":
//           errors[from] = validName(value)
//             ? ""
//             : "Perfer Location is Required and can not be number";
//           break;

//         case "mainSkill":
//           errors[from] = validName(value)
//             ? ""
//             : "Main Skill Field can not be Number or empty";
//           break;
//         default:
//           break;
//       }
//     } else {
//       errors[from] = "";
//     }
//     setValidationErrors((prevErrors) => ({ ...prevErrors, ...errors }));
//     if (Object.values(errors).some((error) => error)) {
//       return false;
//     }
//     return true;
//   };

//   useEffect(() => {
//     if (applicantId.id !== undefined) {
//       getEditData();
//       handleDisable();
//     } else {
//       getprofileData(Login_ID);
//     }
//   }, [applicantId.id, Login_ID, getEditData, handleDisable]);

//   useEffect(() => {}, [
//     formData.applicantName,
//     formData.contactNumber,
//     formData.email,
//     formData.currentLocation,
//     formData.preferedLocation,
//     formData.resume,
//     formData.mainSkill,
//   ]);

//   const handleInputChange = (fieldName, event) => {
//     const { value } = event;
//     setFormData({ ...formData, [fieldName]: value });
//     validation(fieldName, value);
//   };

//   const handleFileChange = (file) => {
//     if (validateFile(file, "application/pdf", 5)) {
//       setFormData({ ...formData, resume: file });
//       setValidationErrors({ ...validationErrors, resume: "" });
//       handleShow();
//     } else {
//       setValidationErrors({
//         ...validationErrors,
//         resume: "File must be pdf and less than 5mb.",
//       });
//     }
//   };

//   const handleType = (key) => {
//     if (key === "resume") {
//       return "button";
//     } else if (
//       key === "totalExperience" ||
//       key === "expectedCTC" ||
//       key === "relevantExperience" ||
//       key === "currentCTC"
//     ) {
//       return "number";
//     } else if (key === "noticePeriod") {
//       return "dropDownSearch";
//     } else {
//       return "text";
//     }
//   };

//   // const handleDisable = () => {
//   //   if (
//   //     validName(formData.applicantName) === true &&
//   //     validContactNo(formData.contactNumber) === true &&
//   //     validEmail(formData.email) === true &&
//   //     validName(formData.currentLocation) === true &&
//   //     validName(formData.preferLocation) === true &&
//   //     formData.resume !== null &&
//   //     validName(formData.mainSkill) === true
//   //   ) {
//   //     setDisabled(false);
//   //   } else {
//   //     setDisabled(true);
//   //   }
//   // };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const formDataObject = new FormData();
//     for (const key in formData) {
//       formDataObject.append(key, formData[key]);
//     }

//     if (disabled === false) {
//       if (applicantId?.id === undefined) {
//         try {
//           const res = await PostAllApplicants(formDataObject);
//           navigateForm("/programmers");
//           SuccessToast(res?.data?.Message);
//         } catch (e) {
//           console.log(e);
//         }
//       } else {
//         try {
//           const res = await UpdateAllApplicant(applicantId.id, formDataObject);
//           if (res?.status === 200) {
//             navigateForm("/programmers");
//             SuccessToast(res?.data?.Message);
//           } else {
//             ErrorToast(res?.data?.Message);
//           }
//         } catch (e) {
//           console.error(e);
//         }
//       }
//     }
//   };

//   const numberValidaData = (e) => {
//     if (e.key === "Backspace" || e.key === "Enter" || e.key === "Tab") {
//       return;
//     }

//     if (e.key === "-" && e.target.value.includes("-")) {
//       e.preventDefault();
//       return;
//     }
//     if (
//       e.key === "-" &&
//       (e.target.value === "" || e.target.selectionStart !== 0)
//     ) {
//       e.preventDefault();
//       return;
//     }

//     // Allow digits
//     if (!/^\d$/.test(e.key)) {
//       e.preventDefault();
//     }
//   };

//   const handleShow = () => setShow((prevShow) => !prevShow);

//   return (
//     <>
//       <div
//         className="flex justify-center relative"
//         style={{
//           backgroundImage: `url(${WelcomeBackground1})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//         }}
//       >
//         {show && (
//           <AddFile
//             onFileChange={handleFileChange}
//             cancel={handleShow}
//             setErr={validationErrors.resume}
//           />
//         )}
//         <div>
//           <div className="flex items-center cursor-pointer mx-1 mt-5 mb-3">
//             <BackButton onClick={"/programmers"} />
//             <div className="pl-4">
//               {applicantId.id !== undefined ? (
//                 <Title
//                   text={"Edit Applicant"}
//                   className="py-10 md:text-4xl 2xl:text-5xl"
//                 />
//               ) : (
//                 <Title
//                   text={"Add Applicant"}
//                   className="py-10 md:text-4xl 2xl:text-5xl"
//                 />
//               )}
//             </div>
//           </div>
//           <div className="border-t-4 py-8 border-warnOrange  pt-14">
//             <div className="bg-lightGry border-solid border-2 shadowform border-warnOrange mb-20 max-[565px]:w-screen ">
//               <div className=" px-[99px] py-8 max-sm:px-9 ">
//                 <div className="w-[700px] max-lg:w-auto ">
//                   <form
//                     onSubmit={handleSubmit}
//                     className=" max-[475px]:flex max-[475px]:flex-col max-[475px]:items-center"
//                   >
//                     {Object.keys(formData).map((key) =>
//                       key !== "recruiterId" ? (
//                         <InputField
//                           key={key}
//                           id={key}
//                           name={key}
//                           className="mb-8 textInput"
//                           type={handleType(key)}
//                           label={fieldName[key] || ""}
//                           ml="auto"
//                           value={
//                             key === "resume"
//                               ? applicantId !== undefined
//                                 ? formData.resume
//                                 : formData.resume.name
//                               : formData[key]
//                           }
//                           onClick={key === "resume" ? handleShow : undefined}
//                           onChange={(e) => handleInputChange(key, e.target)}
//                           onKeyDown={numberValidaData}
//                           error={validationErrors[key]}
//                         />
//                       ) : (
//                         <InputField
//                           key="recruiterName"
//                           id={"recruiterName"}
//                           className={"mb-8"}
//                           type={"text"}
//                           label={"Recruiter Name"}
//                           ml={"auto"}
//                           value={recruiter.recruiterName}
//                           disabled={true}
//                         />
//                       )
//                     )}
//                     <div>
//                       <div className="text-red-600 font-bold italic text-xs pb-3">
//                         * Field Required for submit.
//                       </div>
//                       <div className="flex gap-2 items-center">
//                         <Button
//                           text={"Back"}
//                           type={"button"}
//                           className="bg-warnOrange text-white font-bold text-2xl px-8 py-2 rounded-lg cursor-pointer"
//                           onClick={() => navigate(-1)}
//                         />
//                         <button
//                           type="submit"
//                           className={`bg-warnOrange uppercase ${
//                             !disabled ? "bg-warnOrange" : "bg-gray-300"
//                           } text-white font-bold text-2xl px-8 py-2  ${
//                             !disabled ? "cursor-pointer" : "cursor-default"
//                           } rounded-lg`}
//                           disabled={disabled}
//                         >
//                           {applicantId.id ? "save" : "submit"}
//                         </button>
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AddDeveloperForm;
