import { Button } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Form, InputGroup, Modal } from "react-bootstrap";
import { IoSend } from "react-icons/io5";
import "../ChatModal/Modal.css";
import { checkEmptySingle } from "../../utils/common/Common";

const ChatModal = ({ show, onClose, userId, remarkData, onPostRemark }) => {
  const [chatData, setChatData] = useState([]);
  const [newRemark, setNewRemark] = useState("");


  useEffect(() => {
    setChatData(remarkData);
  }, [remarkData]);

  const postRemark = async () => {
    try {
      let check = checkEmptySingle(newRemark);
      if (check === true) {
        onPostRemark(userId, newRemark);
      }
      setNewRemark("");
    } catch (error) {
      console.error("Error posting remark", error);
    }
  };

  const checkDate = (date) => {
    const remarkDate = moment(date);
    const currentDate = moment();

    if (remarkDate.isSame(currentDate, 'date')) {
      return 'Today';
    } else {
      return remarkDate.format('ll');
    }
  }
  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      dialogClassName="modal-90w chatModal"
      backdrop="static"
      animation={true}
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="text-center">Remarks</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ChatmodalBody border-0">
        {chatData &&
          chatData.length > 0 &&
          chatData.map((item, index) => {
            const remarkDate = checkDate(item.date);
            const showDate = index === 0 || checkDate(chatData[index - 1].date) !== remarkDate;
            // checkDate(item);
            return (
              <div key={item._id}>
                {showDate && (
                  <span className="dateClass">
                    <Badge bg="warning" text="dark">
                      {remarkDate}
                    </Badge>
                  </span>
                )}
                <div className="messageContainer">
                  <div className="messageClass my-4">
                    <span>{item.remark}</span>
                  </div>
                </div>
              </div>
            );
          })}
        <br />
      </Modal.Body>
      <Modal.Footer className="border-0">
        <InputGroup className="sendArea">
          <Form.Control
            as="textarea"
            aria-label="With textarea"
            placeholder="Type Your Message Here..."
            className="text-left"
            value={newRemark}
            onChange={(e) => setNewRemark(e.target.value)}
          />
          <InputGroup.Text>
            <Button className="sendButton" disabled={false} onClick={postRemark}><IoSend color="#F7971E" size={30} /></Button>
          </InputGroup.Text>
        </InputGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ChatModal;