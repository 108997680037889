import React from "react";
import { PrydanLogo } from "../../utils/images/Images";

const PrydanIcon = () => {
  return (
    <a href="https://prydan.com/" target="_blank" rel="northerner noreferrer">
      <img
        src={PrydanLogo}
        className="md:h-10 h-9 md:ml-2 xl:h:36 cursor-pointer"
        alt="Prydan Logo"
      />
    </a>
  );
};

export default PrydanIcon;
