import { useEffect, useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

function Calendar(props) {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const generateCalendarDays = () => {
    const days = [];
    const totalDaysInMonth = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    let dayCounter = 1;

    for (let i = 0; i < 6; i++) {
      const row = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDayOfMonth) {
          const dayFromPrevMonth =
            new Date(currentYear, currentMonth, 0).getDate() -
            firstDayOfMonth +
            j +
            1;
          row.push({
            day: dayFromPrevMonth,
            isCurrentMonth: false,
            month: currentMonth,
            year: currentYear,
          });
        } else if (dayCounter <= totalDaysInMonth) {
          row.push({
            day: dayCounter,
            isCurrentMonth: true,
            month: currentMonth,
            year: currentYear,
          });
          dayCounter++;
        } else {
          const dayFromNextMonth = dayCounter - totalDaysInMonth;
          row.push({
            day: dayFromNextMonth,
            isCurrentMonth: false,
            month: currentMonth + 1,
            year: currentMonth + 1 < 12 ? currentYear : currentYear + 1,
          });
          dayCounter++;
        }
      }
      days.push(row);
    }
    return days;
  };

  const goToPreviousMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(prevDate.getMonth() - 1);
      setCurrentMonth(newDate.getMonth());
      setCurrentYear(newDate.getFullYear());
      return newDate;
    });
  };

  const goToNextMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(prevDate.getMonth() + 1);
      setCurrentMonth(newDate.getMonth());
      setCurrentYear(newDate.getFullYear());
      return newDate;
    });
  };

  return (
    <>
      <div className="shadow-normalShadow bg-white flex flex-col rounded-2xl justify-center w-full md:w-auto h-[31rem]">
        <div className="bg-deepBlue flex relative w-full h-12 items-center justify-between rounded-t-lg">
          <MdOutlineKeyboardArrowLeft
            size={25}
            color="white"
            className="cursor-pointer"
            onClick={goToPreviousMonth}
          />
          <div className="text-white text-lg font-bold">
            {" "}
            {new Intl.DateTimeFormat("en-US", {
              month: "long",
              year: "numeric",
            }).format(currentDate)}
          </div>
          <MdOutlineKeyboardArrowRight
            size={25}
            color="white"
            className="cursor-pointer"
            onClick={goToNextMonth}
          />
        </div>
        <div className="flex items-center justify-center p-2 rounded-sm">
          <table className="table-auto text-center border-deepBlue w-full max-w-md md:max-w-full mx-auto">
            <thead>
              <tr className="items-stretch">
                <th
                  className="py-0 align-middle text-black text-[14px] font-bold"
                  style={{ width: "14.2857%" }}
                >
                  Su
                </th>
                <th
                  className="py-0 align-middle text-black text-[14px] font-bold"
                  style={{ width: "14.2857%" }}
                >
                  Mo
                </th>
                <th
                  className="py-0 align-middle text-black text-[14px] font-bold"
                  style={{ width: "14.2857%" }}
                >
                  Tu
                </th>
                <th
                  className="py-0 align-middle text-black text-[14px] font-bold"
                  style={{ width: "14.2857%" }}
                >
                  We
                </th>
                <th
                  className="py-0 align-middle text-black text-[14px] font-bold"
                  style={{ width: "14.2857%" }}
                >
                  Th
                </th>
                <th
                  className="py-0 align-middle text-black text-[14px] font-bold"
                  style={{ width: "14.2857%" }}
                >
                  Fr
                </th>
                <th
                  className="py-0 align-middle text-black text-[14px] font-bold"
                  style={{ width: "14.2857%" }}
                >
                  Sa
                </th>
              </tr>
            </thead>
            <tbody>
              {generateCalendarDays().map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="border-l-neutral-800 border-deepBlue border-2 items-stretch justify-between gap-5 mt-2 max-md:justify-center"
                >
                  {row.map((cell, colIndex) => (
                    <td
                      key={colIndex}
                      className={`${
                        cell.isCurrentMonth &&
                        cell.day === currentTime.getDate() &&
                        cell.month === currentTime.getMonth() &&
                        cell.year === currentTime.getFullYear()
                          ? "bg-warnOrange text-white rounded-3xl font-semibold"
                          : cell.isCurrentMonth
                          ? "text-black"
                          : "text-gray-500"
                      } py-2 border-deepBlue text-[12px] self-center font-medium border-2`}
                    >
                      {cell.day}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-stretch pb-3 px-3.5 overflow-y-auto">
          <div className="text-deepBlue text-[12px] font-extrabold">
            Events and Holidays
          </div>
          <div className="flex max-md:flex-row items-stretch justify-between gap-4 mt-3">
            <div className="flex flex-col items-stretch w-full sm:w-1 max-md:w-4 lg:w-5">
              <div className="text-black text-xs font-extralight mt-1 text-center">
                <div className="font-medium">Mon</div>
                <div className="text-center font-medium">2</div>
              </div>
              <div className="text-black text-xs font-extralight mt-3 text-center">
                <div className="font-medium">Sun</div>
                <div className="text-center font-medium">15</div>
              </div>
              <div className="text-black text-xs font-extralight mt-3 text-center">
                <div className="font-medium">Fri</div>
                <div className="text-center font-medium">20</div>
              </div>
              <div className="text-black text-xs font-extralight mt-3 text-center">
                <div className="font-medium">Sat</div>
                <div className="text-center font-medium">21</div>
              </div>
              <div className="text-black text-xs font-extralight mt-3 text-center">
                <div className="font-medium">Sun</div>
                <div className="text-center font-medium">22</div>
              </div>
            </div>
            <div className="flex-grow flex flex-col items-stretch w-full md:w-1/2">
              <div className="text-white text-xs font-bold bg-deepBlue p-2 rounded-md">
                Mahatma Gandhi Jayanti
              </div>
              <div className="text-white text-xs font-bold bg-deepBlue mt-3 p-2 rounded-md">
                First Day of Sharad Navratri
              </div>
              <div className="text-white text-xs font-bold bg-deepBlue mt-3 p-2 rounded-md">
                First Day of Durga Puja Festivities
              </div>
              <div className="text-white text-xs font-bold bg-deepBlue mt-3 p-2 rounded-md">
                Maha Saptami
              </div>
              <div className="text-white text-xs font-bold bg-deepBlue mt-3 p-2 rounded-md">
                Maha Ashtami
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-80 bg-warnOrange text-white p-2 pb-1 my-2 rounded-2xl font-bold text-2xl text-center">
        Time: {formattedTime}
      </div>
    </>
  );
}
export default Calendar;
