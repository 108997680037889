import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../utils/images/Images';

const LogoIcon = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/");
    };

    return (
        <img src={Logo} className='mb-3 h-28 max-sm:h-20 2xl:h-28 cursor-pointer' alt='Logo' onClick={() => handleClick()} />
    )
}

export default LogoIcon;
