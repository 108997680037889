import React, { useState } from "react";
import {
  DashboardIcon,
  Logout,
  RecruitersIcon,
} from "../../../utils/images/Images";
import PropTypes from "prop-types";
import MenuButton from "./MenuButton";
import { useLocation, useNavigate } from "react-router-dom";
import Example from "../../Dashboard/LogOut";

const Sidebar = ({ isOpen }) => {
  const [showLogoutPopup, setLogoutPopup] = useState(false);
  const navigate = useNavigate();

  const handleDashboardPage = () => {
    navigate("/dashboard");
  };

  const handleRecruiterPage = () => {
    navigate("/recruiters");
  };

  const handleLogoutClick = () => {
    setLogoutPopup(true);
  };

  const handleLogoutCancel = () => {
    setLogoutPopup(false);
  };

  const location = useLocation();

  return (
    <div
      className={`w-56 flex flex-col bg-deepBlue fixed top-[75px] transition-all duration-700 ease-in-out md:left-0 ${
        isOpen ? "left-0" : "-left-full"
      }`}
      style={{ height: `calc(104vh - 100px)` }}
    >
      <div className="flex flex-col justify-between items-end h-full px-0 pr-0 py-8">
        <div>
          <MenuButton
            onClick={handleDashboardPage}
            btnIcon={DashboardIcon}
            btnText={"Dashboard"}
            active={location.pathname === "/dashboard" ? true : false}
          />

          <MenuButton
            onClick={handleRecruiterPage}
            btnIcon={RecruitersIcon}
            btnText={"Recruiters"}
            active={location.pathname === "/recruiters" ? true : false}
          />
        </div>

        <MenuButton
          onClick={handleLogoutClick}
          btnIcon={Logout}
          btnText={"Logout"}
        />
      </div>
      {showLogoutPopup && <Example onCancel={handleLogoutCancel} />}
    </div>
  );
};
Sidebar.propTypes = {
  isOpen: PropTypes.any,
  isPopupVisible: PropTypes.any,
  setPopupVisible: PropTypes.any,
};
export default Sidebar;
