import React, { useState } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getOrientation } from "get-orientation/browser";
import { styles as classes } from "./styles";
import { getCroppedImg, getRotatedImage } from "./canvas";
import { ImgDialog } from "./dialog";
import { Modal } from "react-bootstrap";
import "./UserDataform.css";
import { IoIosCloseCircleOutline } from "react-icons/io";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

export const Demo = ({ fileAction }) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  // const isBlob = useState(true)
  const open = imageSrc;
 
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        true
      );
      // console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFile = async () => {
    try {
      const croppedImageData = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        false
      );

      // console.log("------------------>", croppedImageData)

      const res = await fileAction(croppedImageData);

      if (res !== false) {
        setImageSrc(null);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    setCroppedImage(null);
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      e.preventDefault();
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      try {
        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn("failed to detect the orientation");
      }

      setImageSrc(imageDataUrl);
    }
  };

  return (
    <div className="flex justify-center items-center">
      {imageSrc ? (
        <Modal show={open} backdrop={"static"}>
          {/* <React.Fragment> */}
          {/* <div className={classes.cropContainer}> */}
          <div className="h-[50vh] w-full relative bg-white">
            <Cropper
              image={imageSrc}
              crop={crop}
              className={"bg-white"}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <div className="absolute right-2 top-2 hover:cursor-pointer">
              <IoIosCloseCircleOutline
                size={30}
                onClick={() => {
                  setImageSrc(null);
                }}
              />
            </div>
          </div>
          <div className={`${classes.controls} p-4`}>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            {/* <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div> */}
            <div className="gap-2 flex">
              <div>
                <Button
                  onClick={showCroppedImage}
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.cropButton }}
                >
                  Show Result
                </Button>
              </div>
              <div>
                <Button
                  onClick={handleFile}
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.cropButton }}
                >
                  Save Result
                </Button>
              </div>
            </div>
          </div>

          {/* </React.Fragment> */}
        </Modal>
      ) : (
        // <input type="file" onChange={onFileChange} className="input"  accept="image/*" />
        <div>
          <label className="label fw-bold">
            <div className="flex">
              Upload file:
              <strong className="text-red-600">*</strong>
            </div>
            {/* <input className="block w-full text-lg  text-white border-2 p-2 border-warnOrange rounded-lg  cursor-pointer bg-warnOrange focus:outline-none " id="large_size" type="file" onChange={onFileChange}  accept="image/*"></input> */}
            <input
              type="file"
              className="block w-full text-lg  text-white border-2 p-2 border-warnOrange rounded-lg  cursor-pointer bg-warnOrange focus:outline-none "
              id="large_size"
              accept="image/*"
              onChange={onFileChange}
            />
          </label>
        </div>
      )}

      <Modal show={croppedImage}>
        <ImgDialog img={croppedImage} onClose={onClose} />
      </Modal>
    </div>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

