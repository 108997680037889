import React from 'react'
import CommingComp from '../../components/Coming Soon/CommingComp'

const Application = () => {
  return (
    <>
      <CommingComp/>
    </>
  )
}

export default Application
