import React from "react";
import { Link } from "react-router-dom";

const AddRecruiterBtn = () => {
  return (
    <div className="flex justify-end items-center">
      <Link
        to="/addRecruiter"
        className="block text-white bg-warnOrange font-bold rounded-lg text-sm px-3 py-2.5 text-center"
        type="button"
      >
        Add Recruiters
      </Link>
    </div>
  );
};

export default AddRecruiterBtn;
