import PropTypes from 'prop-types';
import { FiMenu, FiSearch } from 'react-icons/fi';
import { ImCancelCircle } from "react-icons/im";
import { Link } from 'react-router-dom';
import { Logo } from '../../../utils/images/Images';

const Navbar = ({ isOpenSearchbar, toggleSidebar, toggleSearchbar, toggleSearch }) => {

   

    return (
        <div className='fixed z-10 w-screen max-h-[100px] top-0 left-0 h-30 max-md:w-full'>
            <div className='p-2 bg-white shadow-md h-full flex justify-center items-center'>
                <div className='w-11/12 mx-auto   flex justify-between items-center'>
                    <div className={"max-sm:block md:hidden top-4 left-4 p-2 text-3xl text-warnOrange w-1/3"}>
                        <FiMenu onClick={toggleSidebar} />
                    </div>
                    <div className='flex justify-between max-md:w-2/3 w-full items-center'>

                        <div>
                            <Link to={'/dashboard'}>
                                <div className='w-[100px]  flex justify-between items-center align-middle cursor-pointer'>
                                    <img src={Logo} alt='ATS Logo' />
                                </div>
                            </Link>
                        </div>
                        {
                            toggleSearch ?
                                <div className={`${isOpenSearchbar ? 'max-md:block' : 'max-md:hidden'} flex absolute md:top-5 md:right-8 ${isOpenSearchbar ? 'top-24 right-4' : 'top-0'}`}>
                                    <div className='gap-3 flex mt-3'>
                                        <input className={`md:px-3 md:py-2 ${isOpenSearchbar ? 'px-5 py-1' : 'px-3 py-2'} border-2 rounded  text-deepBlue border-deepBlue shadow-sm focus:outline-none focus:border-deepBlue focus:ring-deepBlue focus:ring-1`} type="text" placeholder="Search here..." />

                                        {isOpenSearchbar ? <ImCancelCircle onClick={toggleSearchbar} className='text-xl mt-2 text-deepBlue' /> : <button className='font-extrabold  text-deepBlue'>Search</button>}
                                    </div>
                                </div>
                                : <span></span>
                        }
                    </div>
                </div>

                <div className=" max-sm:block md:hidden fixed top-4 right-4 p-2 text-3xl text-warnOrange">
                    {
                        toggleSearch ? !isOpenSearchbar ? <FiSearch onClick={toggleSearchbar} /> : <div></div> : <div></div>
                    }
                </div>
                <div>
                    {
                        toggleSearch && isOpenSearchbar ? (
                            <div className='w-[100vw] h-32 bg-warnOrange -z-10 absolute top-10 right-0 pr-9'></div>
                        ) : ""

                        //api toggleSearch ? 
                        // {isOpenSearchbar ? <div className='w-[100vw] h-32 bg-warnOrange -z-10 absolute top-10 right-0 pr-9'></div>
                        //  : <span></span>}
                        //   : <div></div>
                    }
                </div>
            </div>
        </div>
    )
}

Navbar.propTypes = {
    isOpenSearchbar: PropTypes.any,
    toggleSidebar: PropTypes.any,
    toggleSearchbar: PropTypes.any
}
export default Navbar
