import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DashboardID } from "../../../redux/Selector/Selector";
import { GetSingleUsers } from "../../../services/GetService";
import defaultProfileImage from "../../../utils/images/assets/sidebarNavbar/dashboardScreen/ProfileDefault.png";
import ProfileForm from "./UserDataForm";
import SocialMedia from "../../Socialmedia/SocialMedia";

const Profile = () => {
  const navigate = useNavigate();
  // const [profile, setProfile] = useState([]);
  const [profile, setProfile] = useState({});
  const Login_ID = useSelector(DashboardID?.getdashboardId());
  const [showForm, setShowForm] = useState(false);

  // const getprofileData = async () => {
  //   try {
  //     const res = await GetSingleUsers(Login_ID);
  //     if (res?.status === 500) {
  //       window.location.href = "/login";
  //       return;
  //     }

  //     const profileData = res?.data?.Data;

  //     const hasIncompleteDetails =
  //       !profileData?.name ||
  //       !profileData?.descriptionUser ||
  //       !profileData?.linkedIn ||
  //       !profileData?.twitter ||
  //       !profileData?.facebook ||
  //       !profileData?.workplace ||
  //       !profileData?.instagram;

  //     setShowForm(hasIncompleteDetails);
  //     // setProfile(profileData);
  //     setProfile(profileData || {});

  //     if (hasIncompleteDetails) {
  //       // window.location.href = "/user";
  //       navigate("/user");
  //       return;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching profile data:", error);
  //   }
  // };

  // useEffect(() => {
  //   const hasIncompleteDetails =
  //     !profile?.name || !profile?.email || !profile?.descriptionUser;
  //   setShowForm(hasIncompleteDetails);
  // }, [profile]);
  useEffect(() => {
    const hasIncompleteDetails =
      !profile?.name ||
      !profile?.descriptionUser ||
      !profile?.linkedIn ||
      !profile?.twitter ||
      !profile?.facebook ||
      !profile?.workplace ||
      !profile?.instagram;

    setShowForm(hasIncompleteDetails);
  }, [profile]);

  useEffect(() => {
    const getprofileData = async () => {
      try {
        const res = await GetSingleUsers(Login_ID);
        if (res?.status === 500) {
          window.location.href = "/login";
          return;
        }

        const profileData = res?.data?.Data;

        const hasIncompleteDetails =
          !profileData?.name ||
          !profileData?.descriptionUser ||
          !profileData?.linkedIn ||
          !profileData?.twitter ||
          !profileData?.facebook ||
          !profileData?.workplace ||
          !profileData?.instagram;

        setShowForm(hasIncompleteDetails);
        // setProfile(profileData);
        setProfile(profileData || {});

        if (hasIncompleteDetails) {
          // window.location.href = "/user";
          navigate("/user");
          return;
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    getprofileData();
  }, [Login_ID, navigate]);

  const handleFormSave = async (formData) => {
    setProfile(formData);
    setShowForm(false);
  };

  return (
    <div className="shadow-normalShadow rounded-2xl p-2 bg-white">
      {showForm && profile ? (
        <>
          <ProfileForm user={profile} onSave={handleFormSave} />
        </>
      ) : (
        <>
          <div className="flex flex-col-reverse justify-between xl:flex-row lg:justify-between max-md:flex-col max-sm:flex-col ">
            <div className="px-2 flex flex-col pb-2 max-md:px-0 max-md:items-center max-md:pb-5 xl:mt-9">
              <div>
                <h1 className="text-navyBlue font-extrabold   text-[25px] max-md:text-[18px] pb-1">
                  Welcome {profile?.name?.split(" ")[0]}
                </h1>
              </div>
              <div>
                <span className="text-normalGray text-[15px] pb-1">
                  {profile?.descriptionUser}
                </span>
              </div>
            </div>
            <div className="px-2 pt-2">
              <div>
                <div className="w-40 h-40 mx-auto flex justify-center rounded-full overflow-hidden">
                  <img
                    src={profile?.image}
                    alt="User Not Found"
                    className="h-40 w-40"
                    onError={(e) => (e.target.src = defaultProfileImage)}
                  />
                </div>
              </div>
              <div className="flex gap-4 justify-center items-center py-2">
                <SocialMedia Data={profile} />
              </div>
            </div>
          </div>
          {/* <div className="px-2 xl:flex lg:justify-between lg:items-center text-[18px] max-md:text-[12px]">
            <div className="pr-2">
              <span className="text-black font-bold">Joining Date: </span>
              <span className="text-warnOrange font-bold ">
                {profile?.dateOfJoin}
              </span>
            </div>
            <div>
              <span className="text-black font-bold max-md:text-[12px]">
                Email:{" "}
              </span>
              <span className="text-warnOrange font-bold max-md:text-[12px]">
                <a
                  href={`mailto:${profile?.email}`}
                  className="text-warnOrange"
                >
                  {profile?.email}
                </a>
              </span>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default Profile;
