import React from 'react';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const BackButton = ({onClick}) => {
    const navigate = useNavigate();

    return (
        <button className='cursor-pointer bg-warnOrange py-1 lg:py-1 px-2 lg:px-2 text-lg lg:text-xl max-sm:py-1 max-sm:px-1 rounded-md text-white'>
            <IoMdArrowRoundBack size={30} onClick={() => navigate(onClick)} />
        </button>
    );
}


export default BackButton;
