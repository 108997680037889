import PropTypes from 'prop-types';
import React from 'react';

const Button = ({ type, text, onClick, className, disabled ,style}) => {
    return (
        <>
            <button type={type} className={`${className} uppercase`} onClick={onClick} disabled={disabled} style={style}>{text}</button>
        </>
    );
}

Button.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    className: PropTypes.any
};

export default Button;