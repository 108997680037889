import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/Slice/AuthSlice';

function Example({ onCancel }) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const handleClick = () => {
    navigate("/");
    Cookies.remove("loginCookieData")
    Cookies.remove("accessToken")
    Cookies.remove("refreshToken")
    dispatch(logout())
  };

  return (
    <>
      <Modal 
      show={true} 
      centered >
        <Modal.Body className='font-bold text-xl text-center pt-4'>Are you sure, You want to Log Out ?</Modal.Body>
        <Modal.Footer className='gap-3 justify-content-center border-0 pb-3'>
          <Link
            className="px-3 py-2 text-black font-bold bg-gray-100 border-warnOrange hover:bg-white uppercase rounded-lg border-1 text-lg focus:z-10"
            onClick={onCancel}>
            Cancel
          </Link>
          <Button onClick={handleClick} text={"Logout"} className="px-4 py-2 font-bold bg-warnOrange text-white rounded-lg border border-warnOrange text-lg focus:z-10" />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;