import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../src/pages/Welcome/Home";
import "./App.css";
import UserDataForm from "./components/Dashboard/Profile/UserDataForm";
import AddConnection from "./pages/Connection/AddConnection";
import Connection from "./pages/Connections/Connection";
import Dashboard from "./pages/Dashboard/Dashboard";
import Developer from "./pages/Developers/Developer";
import ViewDev from "./pages/Developers/ViewDeveloper.js/ViewDev";
import AddDeveloperForm from "./pages/Forms/AddDeveloperForm";
import Login from "./pages/Login/Login";
import Recruiters from "./pages/Recruiters/RecruiterIndex";
import Recruiterform from "./pages/Recruiters/Recruiterform";
import ViewConnection from "./pages/Connection/ViewConnection";
import { LoginRole, Selector } from "./redux/Selector/Selector";
import Profile from "./components/Dashboard/Profile/Profile";
import Application from "./pages/Applications/Application";
import Position from "./pages/Position/Position";
import ClientPage from "./pages/Client/ClientPage";

const App = () => {
  const login = useSelector(Selector.getLoginInfo());
  const superAdmin = useSelector(LoginRole.getUserRole());

  return (
    <BrowserRouter>
      <Routes>
        {login === true ? (
          <>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/user" element={<UserDataForm />}></Route>
            <Route path="/recruiters" element={<Recruiters />}></Route>
            <Route path="/addRecruiter" element={<Recruiterform />}></Route>
            <Route path="/programmers" element={<Developer />}></Route>
            <Route path="/addProgrammer" element={<AddDeveloperForm />}></Route>
            <Route path="/profile" element={<Profile/>}></Route>
            <Route
              path="/editProgrammer/:id"
              element={<AddDeveloperForm />}
            ></Route>
            <Route path="/programmers/:id" element={<ViewDev />}></Route>
            <Route path="/application" element={<Application/>}></Route>
            <Route path="/position" element={<Position/>}></Route>
            <Route path="/client" element={<ClientPage/>}></Route>
            {superAdmin === "Super Admin" ? (
              <>
                <Route path="/connection" element={<Connection />}></Route>
                <Route
                  path="/viewConnection/:id"
                  element={<ViewConnection />}
                ></Route>
                <Route
                  path="/addConnection"
                  element={<AddConnection />}
                ></Route>
                <Route
                  path="/addConnection/:id"
                  element={<AddConnection />}
                ></Route>
                <Route
                  path="/editConnection/:id"
                  element={<AddConnection />}
                ></Route>
              </>
            ) : (
              <Route path="/dashboard" element={<Dashboard />}></Route>
            )}
          </>
        ) : (
          <>
            <Route path="/" element={<Home />}></Route>
            <Route path="/login" element={<Login />}></Route>
          </>
        )}

        <Route path="*" element={login ? <Dashboard /> : <Login />  } />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
