import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import * as React from "react";

const ITEM_HEIGHT = 48;

const LongMenu = ({
  options,
  rowData,
  // deleteAction,
  // viewAction,
  // editAction,
  // remarkAction
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const selectAction = (event) => {
  //   console.log(event);
  //   switch (event) {
  //     case "View":
  //       viewAction(rowData?._id);
  //       break;
  //     case "Edit":
  //       editAction(rowData._id);
  //       break;
  //     case "Delete":
  //       deleteAction(rowData._id);
  //       break;
  //     case 'Remarks':
  //       remarkAction(rowData._id);
  //       break;
  //     case "Follow Up":
  //       break;
  //     default:
  //       break;
  //   }
  //   handleClose();
  // };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
        onChange={(e) => {
          console.log("changes", e.target?.value);
        }}
        onSelect={(e) => {
          console.log("select", e.target?.value);
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.action}
            value={option.action}
            className="menuItem"
            selected={option.action === "Pyxis"}
            // onClick={() => selectAction(option.action)}
            onClick={() => {option?.selectionFunc(rowData); handleClose();}}
          >
            <span className="mx-2 menuItem">
              {option.com}
              {"  "}
            </span>
            {option.action}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

LongMenu.propTypes = {
  rowData: PropTypes.object,
  deleteAction: PropTypes.func,
  options: PropTypes.array,
  viewAction: PropTypes.func,
  editAction: PropTypes.func,
  remarkAction: PropTypes.func,
};

export default LongMenu;
