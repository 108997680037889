import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../components/Toast/ToastCom";
import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon, WelcomeBackground1 } from "../../utils/images/Images";
import BackButton from "../BackButton/BackButton";
import Title from "../Title/Title";
import Label from "../InputField/Label";
import HandleIcon from "../InputField/HandleIcon";

const ViewData = ({ stateObj, getAction, userId, handleSign = null, name }) => {
  const navigate = useNavigate();
  const dataId = userId;

  const [formData, setFormData] = useState({});
  const [fieldName, setFieldName] = useState({});

  useEffect(() => {
    const initialFormData = Object.fromEntries(
      Object.keys(stateObj).map((key) => [key, ""])
    );
    setFormData(initialFormData);
    setFieldName(stateObj);
    getEditData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObj]);

  const socialMedia ={ 
    linkedin:{ fieldName: 'Linkedin', key: 'linkedin', icon: LinkedinIcon, value: formData?.linkedin },
    instagram: { fieldName: 'Instagram', key: 'instagram', icon: InstagramIcon , value: formData?.instagram },
    facebook:{ fieldName: 'Facebook', key: 'facebook', icon: FacebookIcon , value: formData?.facebook },
    twitter: { fieldName: 'Twitter', key: 'twitter', icon: TwitterIcon , value: formData?.twitter },
  };

  const getEditData = async () => {
    try {
      const res = await getAction(dataId);
      const data = res?.data?.Data;
      if (res?.status === 200) {
        const updatedFormData = Object.fromEntries(
          Object.keys(stateObj).map((key) => [key, data?.[key] || ""])
        );
        setFormData(updatedFormData);
      } else {
        ErrorToast(res?.Message);
      }
    } catch (e) {
      console.error(e);
    }
  };
  console.log(formData);
  return (
    <>
      <div
        className="flex justify-center relative"
        style={{
          backgroundImage: `url(${WelcomeBackground1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div>
          <div className="flex items-center cursor-pointer mx-1">
            <BackButton onClick={-1} />
            <div className="pl-4">
              <Title
                text={`View ${name}`}
                className="py-10 md:text-4xl 2xl:text-5xl"
              />
            </div>
          </div>
          <div className="border-t-4 py-8 border-warnOrange  pt-14">
            <div className="bg-lightGry border-solid border-2 shadowform border-warnOrange mb-20 max-[565px]:w-screen ">
              <div className=" px-[99px] py-8 max-sm:px-9 ">
                <div className="w-[700px] max-lg:w-auto ">
                  <div className=" max-[475px]:flex max-[475px]:flex-col ">
                    {Object.keys(formData).map((item, index) => {
                      let sign;
                      if (handleSign != null) {
                        sign = handleSign(item);
                      }
                      
                      if ((item === "linkedin" || item === "instagram" || item === "twitter" || item === "facebook")) {
                        return (
                          <HandleIcon obj={socialMedia[item]} className={"mb-8"}/>
                        );
                      } else {
                        return <Label
                          key={index}
                          className={"mb-8 "}
                          label={fieldName[item] || ""}
                          value={item === "recruiterId" ? formData[item]?.name : formData[item]}
                          spSign={sign}
                        />
                      }
                    })}
                    <div>
                      <div className="flex gap-2 items-center">
                        <div
                          className="bg-warnOrange text-white font-bold text-2xl px-8 py-2 rounded-lg cursor-pointer max-[475px]:text-base"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewData;
