import React from 'react'
import { WelcomeBackground1 } from "../../utils/images/Images";

const BackgroundImage = ({children,className}) => {
return (
    <div
        className={className}
        style={{
            backgroundImage: `url(${WelcomeBackground1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
        >
            {children}
    </div>        
)
}

export default BackgroundImage