import React from "react";
import { Link } from "react-router-dom";
import {
  BusinessProfessional,
  Client,
  Position,
  Programmer,
} from "../../../utils/images/Images";
import { useSelector } from "react-redux";
import { LoginRole } from "../../../redux/Selector/Selector";

const Tabs = () => {
  const superAdmin = useSelector(LoginRole.getUserRole());

  const tabs = [
    {
      image: Programmer,
      label: "Applicants",
      className: "text-navyBlue",
      link: "/programmers",
    },
    {
      image: BusinessProfessional,
      label: "Application",
      className: "text-deepBlue",
      link: "/application",
    },
    {
      image: Position,
      label: "Position",
      className: "text-deepBlue",
      link: "/position",
    },
    {
      image: Client,
      label: "Client",
      className: "text-navyBlue",
      link: "/client",
    },
  ];

  const suparTabData = {
    // image: {Client},
    label: "Connection",
    className: "text-navyBlue",
    link: "/connection",
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 max-lg:gap-6 max-md:grid-cols-2 max-md:gap-4 xl:gap-8 max-xl:gap-8">
      {tabs.map((item, index) => (
        <Link key={index} to={item.link}>
          <div className="shadow-normalShadow h-28 bg-white rounded-2xl flex justify-center items-center flex-col cursor-pointer max-md:w-full max-md:mx-2 max-sm:w-full max-sm:mx-auto ">
            <div className="w-20 h-20 max-md:w-16 max-md:h-16 max-lg:w-14 max-lg:h-14 max-xl:h-12 xl:h-12 xl: mt-2">
              <img
                src={item.image}
                alt="icon"
                className="w-full h-full object-contain"
              />
            </div>
            <div
              className={`font-semibold text-2xl max-md:py-0 max-md:text-xl ${item.className}`}
            >
              {item.label}
            </div>
          </div>
        </Link>
      ))}

      {superAdmin === "Super Admin" && (
        <Link to={suparTabData.link}>
          <div className="shadow-normalShadow h-28 bg-white rounded-2xl flex justify-center items-center flex-col cursor-pointer max-md:w-full max-md:mx-2 max-sm:w-full max-sm:mx-auto ">
            <div className="w-20 h-20 max-md:w-16 max-md:h-16 max-lg:w-14 max-lg:h-14 max-xl:h-12 xl:h-12 xl:">
              <img
                src={Client}
                alt="icon"
                className="w-full h-full object-contain "
              />
            </div>
            <div
              className={`font-semibold text-2xl max-md:py-0 max-md:text-xl ${suparTabData.className}`}
            >
              {suparTabData.label}
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default Tabs;
