import React from "react";
import "./InputField.css";

//important
//here we pass 1)type 2)name 3)onchange 4)placeholder 5)value 6)color

const InputField = ({
  type,
  name,
  onChange,
  placeholder,
  value,
  className,
  label,
  ml,
  id,
  error = "",
  onClick,
  disabled = false,
  onBlur,
  onKeyDown,
}) => {
  const handleStar = () => {
    return !!(
      name === "mainSkill" ||
      name === "resume" ||
      name === "preferLocation" ||
      name === "currentLocation" ||
      name === "email" ||
      name === "contactNumber" ||
      name === "applicantName" ||
      name === "firstName" ||
      name === "lastName" ||
      name === "mobileNo" ||
      name === "company" ||
      name === "photo"
    );
  };

  return (
    <>
      <div
        className={`flex justify-between max-[470px]:flex-col  ${className}`}
      >
        <div className="flex items-center">
          <label for={id} className="text-navyBlue">
            <b>{label}</b>
            {handleStar() ? <b className="text-red-600 font-bold">*</b> : ""}
          </label>
        </div>
        <div>
          {type === "dropDownSearch" ? (
            //<DropSearch />
            <div className="relative">
              <select
                id="dropdown"
                name="dropdown"
                className=" text-warnOrange dropDownSearch font-bold p-3 rounded-lg w-96 h-15 border-2 border-navyBlue appearance-none"
                value={value}
                onChange={onChange}
              >
                <option className="dropDownSearchStyle" value="1">
                  1
                </option>
                <option className="dropDownSearchStyle" value="2">
                  2
                </option>
                <option className="dropDownSearchStyle" value="3">
                  3
                </option>
                <option className="dropDownSearchStyle" value="4">
                  4
                </option>
                <option className="dropDownSearchStyle" value="5">
                  5
                </option>
                <option className="dropDownSearchStyle" value="6">
                  6
                </option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 12l-6-6-1.414 1.414L10 14.828l7.414-7.414L16 6z" />
                </svg>
              </div>
            </div>
          ) : (
            <input
              id={id}
              type={type}
              name={name}
              className={`text-warnOrange  font-bold p-3 rounded-lg w-96  h-15 border-2 border-navyBlue ${
                type === "text"
                  ? "max-[810px]:w-auto"
                  : "max-[810px]:w-[230px] textInput"
              } `}
              style={{ marginLeft: ml }}
              onChange={onChange}
              placeholder={placeholder}
              value={value}
              onClick={onClick}
              disabled={disabled}
              onBlur={onBlur}
              onKeyDown={type === "number" ? onKeyDown : null}
              autoComplete="off"
            />
          )}
          <span className="block h-1">
            {error !== "" ? (
              <p className="text-red-500 text-xs font-bold max-[810px]:w-[230px]">
                {error}
              </p>
            ) : (
              ""
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default InputField;
