import {
  ADDAPPLICANT,
  ADDRECRUITER_URL,
  APPLICANTS_URL,
  APPLICANT_DATA_URL,
  APPLICANT_SORT,
  CONNECTION,
  CONNECTION_REMARK,
  CONNECTION_URL,
  GET_CONNECTION_URL,
  LOGIN_URL,
  RECRUITER_URL,
  REGISTRATION_URL,
  RESET_PASSWORD_URL,
  RECRUITER,
  PATCH_CONNECTION,
  FORGOT_PASSWORD,
  OTP_VERIFICATION,
  PASSWORD_UPDATE,
} from "../constant/Api";
import { deleteApi, patch, post } from "./ApiMethods";

/*
    -> "Set up the API methods in this format, please."
            example: 
                export const APIMethodName + AnyName + API = async(params) => {
                    return methodName(URL_NAME, params)
                }
*/

// AUTHENTICATION ALL APIS METHODS
export const UserRegistration = async (params) => {
  return post(REGISTRATION_URL, params);
};

export const UserLogin = async (params) => {
  return post(LOGIN_URL, params);
};

export const ResetPassword = async (params) => {
  return post(RESET_PASSWORD_URL, params);
};
export const ForgotPassword = async (params) => {
  return post(FORGOT_PASSWORD, params);
};
export const OtpVerification = async (params) => {
  return post(OTP_VERIFICATION, params);
};
export const UpdatePassword = async (params) => {
  return post(PASSWORD_UPDATE, params);
};

// USERS ALL APIS METHODS

export const GetAllUsers = async () => {
  return post(RECRUITER);
};
export const UpdateUser = async (id, params) => {
  return patch(RECRUITER_URL + id, params);
};

export const DeleteUsers = async (id) => {
  return patch(RECRUITER_URL + id);
};

//post recruiter api
export const AddRecruiter = async (params) => {
  return post(ADDRECRUITER_URL, params);
};

// APPLICANTS ALL API METHODS
export const PostAllApplicants = async (params) => {
  return post(ADDAPPLICANT, params);
};

export const PostAllApplicantsData = async (page, limit) => {
  const params = { PageNo: page, PageLimit: limit };
  return post(APPLICANT_DATA_URL, params);
};

export const UpdateAllApplicant = async (id, params) => {
  return patch(APPLICANTS_URL + id, params);
};

export const DeleteApplicantData = async (id, params) => {
  return deleteApi(APPLICANTS_URL + id, params);
};

export const SearchSingleApplicant = async (id) => {
  return post(APPLICANT_SORT + id);
};

//CONNECTIONS ALL API METHODS
export const PostAllConnectionData = async (page, limit) => {
  const params = { PageNo: page, PageLimit: limit };
  return post(GET_CONNECTION_URL, params);
};

export const DeleteConnectionData = async (id, params) => {
  return deleteApi(CONNECTION_URL + id, params);
};
export const PostAllConnection = async (params) => {
  return post(CONNECTION, params);
};

export const UpdateAllConnection = async (id, params) => {
  return patch(PATCH_CONNECTION + id, params);
};

export const PostConnectionRemark = async (id, params) => {
  return patch(CONNECTION_REMARK + id, params);
};
