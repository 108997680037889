import React from 'react'
import { Link } from "react-router-dom";

const HandleIcon = ({obj,className}) => {
    console.log('obj', obj);
  return (
    <div>
      <div className={`flex max-sm:flex-col ml-auto ${className}`}>
            <div className='w-1/2'>
                <label className='text-navyBlue text-xl'><b>{obj?.fieldName}</b></label>
            </div>
            <div className='w-1/2 text-warnOrange font-extrabold text-xl'>
                {/* {validEmail(value) || validContactNo(value) ? (<div className="cursor-pointer hover:underline"
                    onClick={handleMailClick}>{value}</div>) : ()
                } */}
                {/* <div>{obj.icon}</div> */}
                {/* <LinkedinIcon /> */}
                <Link target='_blank' to={obj.value} className="cursor-pointer" ><img src={obj?.icon} alt='icon'/></Link>
            </div>
        </div>
    </div>
  )
}

export default HandleIcon
