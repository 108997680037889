import React from 'react';
import Logo from '../../utils/images/assets/logo/ATSLogo.svg';

const LogoATS = () => {
  return (
    <div className='w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2'>
      <img
        className='object-contain w-full h-auto'
        src={Logo}
        alt="ats logo"
      />
    </div>
  )
}


export default LogoATS