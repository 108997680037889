import { createSelector } from "@reduxjs/toolkit";

//state declaration
const selectAuth = (state) => state?.auth?.IsLogin;
const selectLoginID = (state) => state?.auth?.loginData?.userDetails?.id;
const selectRole = (state) => state?.auth?.loginData?.userDetails?.role;
const selectUserData = (state) => state?.auth?.profileData;

// selectors creation
const selectUserID = createSelector(
    [selectAuth],
    (auth) => auth
);

const loginDataId = createSelector(
    [selectLoginID],
    (auth) => auth
)
const loginRole = createSelector(
    [selectRole],
    (auth) => auth
)
// const loginEmail = createSelector(
//     [selectUserData],
//     (auth) => auth
// )

const profileData = createSelector(
    [selectUserData],
    (auth) => auth
)
//exporting selectors
export const Selector = {
    getLoginInfo: () => selectUserID,
}
export const DashboardID = {
    getdashboardId: () => loginDataId,
}
export const LoginRole = {
    getUserRole: () => loginRole,
}

export const LoginData = {
    getUserData: () => profileData,
}