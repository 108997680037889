import React, { useState } from "react";
import Navbar from "../../components/Header/Navbar/Navbar";
import Sidebar from "../../components/Header/Navbar/Sidebar";
import Title from "../../components/Title/Title";
import Recruiters from "./Recruiters";
import { useSelector } from "react-redux";
import { LoginRole } from "../../redux/Selector/Selector";
import { WelcomeBackground1 } from "../../utils/images/Images";
import AddRecruiterBtn from "./AddRecruiterBtn";

const RecruiterIndex = () => {
  const pageURI = "/dasboard";
  const superAdmin = useSelector(LoginRole.getUserRole());

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSearchbar, setIsOpenSearchbar] = useState(false);
  const [isOpenSearchbars, setIsOpenSearchbars] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setIsOpenSearchbar(isOpenSearchbar ? !isOpenSearchbar : isOpenSearchbar);
  };

  const toggleSearchbar = () => {
    setIsOpenSearchbar(!isOpenSearchbar);
    setIsOpen(isOpen ? !isOpen : isOpen);
  };
  const toggleSearch = () => {
    if (pageURI === "/dashboard") {
      setIsOpenSearchbars(false);
    } else {
      setIsOpenSearchbars(true);
    }
  };
  return (
    <div>
      <Navbar
        isOpenSearchbar={isOpenSearchbar}
        toggleSidebar={toggleSidebar}
        toggleSearchbar={toggleSearchbar}
        toggleSearch={toggleSearch}
        isOpenSearchbars={isOpenSearchbars}
      />
      <div className="flex w-full mt-[75px]">
        <Sidebar isOpen={isOpen} />
        <div
          className=" w-full p-6 md:pl-64 min-h-screen md:block h-full"
          style={{
            backgroundImage: `url(${WelcomeBackground1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex justify-between items-center">
            <div className="w-5/6 max-sm:w-full">
              <Title
                text={"Recruiters List"}
                className="text-center md:text-4xl"
              />
            </div>
            {superAdmin === "Super Admin" ? <AddRecruiterBtn /> : ""}
          </div>
          <div>
            <Recruiters />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterIndex;
