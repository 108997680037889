import React from 'react';
import {  LoginRocketLaunch } from '../../utils/images/Images';

const RocketGif = () => {
  return (
    <div className='h-full'>
      <img
        className='w-full h-full object-cover'
        // src={LoginGIFRocketLaunch}
        src={LoginRocketLaunch}
        alt='not found'
      />
    </div>
  )
}

export default RocketGif;