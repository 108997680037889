import React from 'react'
import Button from '../Button/Button'
import { WelcomeBackground1 } from '../../utils/images/Images'
import { useNavigate } from 'react-router-dom'

const CommingComp = () => {

    const navigate = useNavigate();

    return (
        <div className='flex h-screen justify-center items-center' style={{
            backgroundImage: `url(${WelcomeBackground1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
            <div className="shadow-normalShadow rounded-2xl p-2  bg-white m-10">
                <div class="min-h-96  flex flex-col items-center sm:px-20 justify-center">
                    <h1 class="text-5xl  font-bold mb-8 text-center">
                        Coming Soon
                    </h1>
                    <p class=" text-lg mb-8 text-center">
                        We're working hard to bring you something amazing. Stay tuned!
                    </p>
                    <Button text={"Back To Home"} className={"bg-warnOrange text-white p-2 rounded-md font-bold"} onClick={()=>navigate("/dashboard")}/>
                </div>
            </div>
        </div>
    )
}

export default CommingComp
