import { AiTwotoneCheckCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.minimal.css";

export const SuccessToast = (Message) => {
  toast.success(Message, {
    position: toast.POSITION.TOP_RIGHT,
    style: {
      background: "#F7971E",
      color: "white",
      fontWeight: "600",
    },
    icon: <AiTwotoneCheckCircle size={30} />,
  });
};

export const ErrorToast = (Message) => {
  toast.error(Message, {
    position: toast.POSITION.TOP_RIGHT,
    style: {
      background: "rgb(245 245 245)",
      color: "black",
      fontWeight: "600",
    },
  });
};

export const WarningToast = (Message) => {
  toast.warning(Message, {
    position: toast.POSITION.TOP_RIGHT,
    style: {
      background: "#F7971E",
      color: "white",
      fontWeight: "600",
    },
  });
};

export const InfoToast = () => {
  toast.info("Information Notification !", {
    position: toast.POSITION.BOTTOM_CENTER,
    style: {
      background: "#F7971E",
      color: "white",
      fontWeight: "600",
    },
  });
};

export const DefaultToast = () => {
  toast("Default Notification !", {
    position: toast.POSITION.BOTTOM_LEFT,
    style: {
      background: "#F7971E",
      color: "white",
      fontWeight: "600",
    },
  });
};
