import React from "react";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../components/Logo/Logo";
import PrydanIcon from "../../components/Logo/PrydanLogo";
import {
  WelcomeGirl,
  WelcomeInnerBackground,
} from "../../utils/images/Images";

const Home = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/login");
  };

  return (
    <div
      className="relative h-screen flex items-center justify-center"
      style={{
        // backgroundImage: `url(${WelcomeBackground1})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        background :'#BBC8FF'
      }}
    >
      <div
        className="grid grid-cols-1 lg:grid-cols-2 w-10/12 h-5/6 mx-auto 2xl:px-16 lg:w-3/4 max-sm:w-full max-sm:h-full overflow-hidden relative"
        style={{
          backgroundImage: `url(${WelcomeInnerBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-max flex flex-col justify-between py-8 px-4 sm:px-8 z-10">
          <div>
            <LogoIcon />
            <p className="text-deepBlue font-extrabold text-4xl md:py-0 py-3 md:text-4 xl:text-5xl 2xl:text-5xl">
              Welcome to ATS
            </p>
            <p className="bg-clip-text text-transparent bg-gradient-to-r from-OrangeRed to-deepBlue font-extrabold text-2xl 2xl:mb-10 mb-4 py-4 lg:text-2xl xl:text-4xl 2xl:text-4xl">
              Applicant Tracking System
            </p>
            <button
              className="bg-gradient-to-r from-warnOrange to-vividYellow text-white focus-visible:outline-none outline-0 px-7 py-2 uppercase rounded font-extrabold lg:text-2xl  xl:text-2xl border-none tracking-wider"
              onClick={() => handleClick()}
            >
              Continue
            </button>
          </div>
          <div>
            <div className="flex flex-wrap justify-start items-center gap-2 text-center">
              <div className="text-center text-white text-base md:text-2xl xl:text-3xl font-extrabold">
                Presented By:
              </div>
              <div className="text-center">
                {" "}
                <PrydanIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 -right-16 lg:right-0 w-64 sm:w-96 md:w-72 md:-right-5 2xl:w-[28rem] lg:w-80  z-0">
          <img src={WelcomeGirl} alt="girl" />
        </div>
      </div>
    </div>
  );
};

export default Home;
