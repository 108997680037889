import React from "react";
import {  useParams } from "react-router-dom";
import { GetSingleApplicant } from "../../../services/GetService";
import ViewData from "../../../components/FormItems/ViewData";

const ViewDev = () => {
  const applicantId = useParams();

  const fieldName = {
    applicantName: "Applicant Name:",
    contactNumber: "Contact Number:",
    email: "Email:",
    recruiterId: "Recruiter Name:",
    totalExperience: "Total Experience:",
    relevantExperience: "Relevant Experience:",
    currentCTC: "Current CTC:",
    expectedCTC: "Expected CTC:",
    noticePeriod: "Notice Period:",
    currentLocation: "Current Location:",
    preferedLocation: "Prefer Location:",
    companyName: "Company Name:",
    resume: "Resume:",
    mainSkill: "Main Skill:",
    otherSkill: "Other Skill:",
  };

  const getApplicant = (id) => {
    if (id !== undefined) {
      const res = GetSingleApplicant(id);
      return res;
    }
  };

  const handleSigns = (item) => {
    if (item === "totalExperience" || item === "relevantExperience") {
      return " year";
    } else if (item === "currentCTC" || item === "expectedCTC") {
      return " ₹";
    } else if (item === "noticePeriod") {
      return " month";
    }
  };

  return (
    <>
      <ViewData
        stateObj={fieldName}
        userId={applicantId?.id}
        getAction={getApplicant}
        name={"Applicant"}
        handleSign={handleSigns}
      />
    </>
  );
};

export default ViewDev;
