import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BiSolidCommentDetail, BiSolidEditAlt } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { SlUserFollowing } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import LongMenu from "../../components/Actions";
import BackButton from "../../components/BackButton/BackButton";
import Button from "../../components/Button/Button";
import ChatModal from "../../components/ChatModal/Modal";
import Navbar from "../../components/Header/Navbar/Navbar";
import Sidebar from "../../components/Header/Navbar/Sidebar";
import Loader from "../../components/Loader/Loader";
import Title from "../../components/Title/Title";
import { ErrorToast } from "../../components/Toast/ToastCom";
import { GetSingleConnection } from "../../services/GetService";
import {
  DeleteConnectionData,
  PostAllConnectionData,
  PostConnectionRemark,
} from "../../services/PostService";
import DeleteDeveloper from "../Developers/DeleteDeveloper";
import "../Developers/developer.css";
import BackgroundImage from "../../components/BackgroundImage/backgroundImage";

const Connection = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const paginationPerPage  = 10;
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [showRemarkID, setShowRemarkID] = useState("");
  const [remarkData, setRemarkData] = useState([]);
  const route = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSearchbar, setIsOpenSearchbar] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setIsOpenSearchbar(isOpenSearchbar ? !isOpenSearchbar : isOpenSearchbar);
  };

  const toggleSearchbar = () => {
    setIsOpenSearchbar(!isOpenSearchbar);
    setIsOpen(isOpen ? !isOpen : isOpen);
  };

  const getConnectionsData = async () => {
    try {
      setLoading(true);
      const res = await PostAllConnectionData(page, paginationPerPage);
      const devData = res?.data?.Data;
      setConnections(devData);
    } catch (error) {
      console.error("Error fetching applicants:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteRecord = async () => {
    try {
      const url = await DeleteConnectionData(deleteId);
      if (url?.data?.Status === 200) {
        getConnectionsData();
        setShowDelete(false);
      } else {
        ErrorToast("Error Occurred");
      }
    } catch (e) {
      console.error("Error is ", e);
    }
  };

  const openDeleteModal = (data) => {
    setShowDelete(true);
    setDeleteId(data._id);
  };

  const handleView = (data) => {
    route(`/viewConnection/${data._id}`);
  };

  const openRemarkModal = (userId) => {
    setShowRemarkID(userId._id);
    setShowRemarksModal(true);
    getConnectionRemarks(userId._id);
  };

  const getConnectionRemarks = async (userId) => {
    try {
      const res = await GetSingleConnection(userId);
      setRemarkData(res?.data?.Data?.remarks);
    } catch (error) {
      console.error("Error fetching connection remarks:", error);
    }
  };

  const postRemark = async (userId, newRemark) => {
    try {
      const res = await PostConnectionRemark(userId, { remark: newRemark });
      getConnectionRemarks(userId);
    } catch (error) {
      console.error("Error posting remark", error);
    }
  };

  const handleEdit = (data) => {
    route(`/addConnection/${data._id}`);
  };

  const options = [
    { action: "View", com: <BsEye />, selectionFunc: handleView },
    { action: "Edit", com: <BiSolidEditAlt />, selectionFunc: handleEdit },
    { action: "Delete", com: <MdDelete />, selectionFunc: openDeleteModal },
    {
      action: "Remarks",
      com: <BiSolidCommentDetail width={100} height={100} />,
      selectionFunc: openRemarkModal,
    },
    { action: "Follow Up", com: <SlUserFollowing />, selectionFunc: () => {} },
  ];

  const columns = [
    {
      name: "No.",
      selector: (row, index) => {
        const realIndex = index + 1 + (page - 1) * paginationPerPage;
        return <span>{realIndex}.</span>;
      },
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => <span className="capitalize">{row.firstName ? row.firstName : "---"} </span>,
      // width: "7%",
    },
    {
      name: "Contact",
      selector: (row) =>
        row.mobileNo === "" ? (
          "---"("-"("-"))
        ) : (
          <span
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `tel:${row.mobileNo}`;
            }}
            className="cursor-pointer"
          >
            {row.mobileNo}
          </span>
        ),
      // width: "8%",
    },
    {
      name: "Email",
      selector: (row) =>
        row.email === "" ? (
          "---"("-"("-"))
        ) : (
          <span
            onClick={(e) =>{
              e.preventDefault();
             window.location.href =`mailto:${row.email}`;
            }}
            className="cursor-pointer"
          >
            {row.email}
          </span>
        ),
      width: "11%",
    },

    {
      name: "Company",
      selector: (row) => row.company ? row.company : "---",
      // width: "8%",
    },
    {
      name: "Designation",
      selector: (row) => <span className="capitalize">{row.designation ? row.designation : "---"}</span>,
      // width: "10%",
    },
    {
      name: "City",
      selector: (row) => <span className="capitalize">{row.city ? row.city : "---"}</span>,
      // width: "10%",
    },
    {
      name: "State",
      selector: (row) => <span className="capitalize">{row.state ? row.state : "---"}</span>,
      // width: "10%",
    },
    {
      name: "Current Location",
      selector: (row) => <span className="capitalize">{row.location ? row.location : "---"}</span>,
      // width: "12%",
    },
    {
      name: "Referred",
      selector: (row) => row.referredBy ? row.referredBy : "---",
      // width: "8%",
    },
    {
      width: "4%",
      cell: (row) => (
        <LongMenu
          options={options}
          rowData={row}
          // deleteAction={openDeleteModal}
          // viewAction={handleView}
          // remarkAction={openRemarkModal}
        />
      ),
    },
  ];



  const customStyles = {
    headRow: {
      style: {
        fontSize: "12px",
        borderBottom: "2px solid var(--LIGHTORANGE) !important",
        color: "var(--LIGHTORANGE)",
        fontWeight: "700",
        "@media (max-width: 425px)": {
          fontSize: "0.8rem",
        },
        "@media (max-width: 768px)": {
          fontSize: "14px",
        },
        "@media (min-width: 768px) and (max-width: 1024px)": {
          fontSize: "16px",
        },
        "@media (min-width: 1440px)": {
          fontSize: "18px",
        },
        "@media (min-width: 2560px)": {
          fontSize: "20px",
        },
      },
    },
    headCells: {
      style: {
        padding: "10px",
        borderBottom: "1px solid var(--LIGHTORANGE)",
        "@media (min-width: 2560px)": {
          padding: "20px",
        },
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid var(--LIGHTORANGE) !important",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
        textAlign: "center",
        fontWeight: "600",
        "@media (max-width: 425px)": {
          fontSize: "0.8rem !important",
        },
        "@media (max-width: 768px)": {
          fontSize: "1rem",
        },
        "@media (max-width: 1024px)": {
          fontSize: "1rem",
        },
        "@media (min-width: 1440px)": {
          fontSize: "14px",
        },
        "@media (min-width: 2560px)": {
          fontSize: "1.5rem",
        },
      },
    },
    // pagination: {
    //   style: {
    //     fontSize: "1.2rem",
    //     maxWidth: "100%",
    //     margin: "0 auto",
    //   },
    // },
    table: {
      backgroundColor: "var(--RED) !important",
    },
  };

  const handlePageChange = (e) => {
    setPage(e);
    getConnectionsData();
  };


  useEffect(() => {
    getConnectionsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
    <Navbar
        isOpenSearchbar={isOpenSearchbar}
        toggleSidebar={toggleSidebar}
        toggleSearchbar={toggleSearchbar}
      />
      <div className="flex w-full mt-[75px]">
        <Sidebar isOpen={isOpen} />
        <BackgroundImage className={'w-full min-h-screen md:block'}>
            <div className="w-full p-6 md:pl-64 md:pr-15 min-h-screen md:block">
                  <div className="max-md:flex-col max-lg:flex-col justify-evenly max-md:mx-auto h-full">
                  <div className="flex flex-row max-md:flex-col justify-between items-center w-full">
                    <div className="flex items-center cursor-pointer max-md:px-0">
                      <BackButton onClick={"/dashboard"} />
                    </div>
                    <div>
                        <Title text={"All Connections"} />
                      </div>
                    {/* search code for future use  */}
                    {/* <div className=" py-3 px-3 max-md:px-0">
                      <div className="gap-3 flex">
                        <input
                          className="md:px-3 md:py-2 border-2 rounded  text-black border-black shadow-sm focus:outline-none focus:border-black focus:ring-black focus:ring-1"
                          type="text"
                        />
                        <button className="font-extrabold  text-black text-xl">
                          Search
                        </button>
                      </div>
                    </div> */}
                    <div className="flex flex-wrap items-center justify-between py-3 max-md:flex-nowrap">
                      <div className="pr-2 pb-2 max-md:w-full md:pl-4">
                        <Button
                          text="ADD"
                          onClick={() => navigate("/addConnection")}
                          className="bg-warnOrange text-white uppercase font-extrabold py-2 lg:py-3 px-4 lg:px-6 text-lg lg:text-xl rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="border-warnOrange py-3 lg:py-5 w-full border-t-2 " />
                  <div className="text-xl">
                    <DataTable
                      columns={columns}
                      data={connections}
                      customStyles={customStyles}
                      fixedHeader
                      responsive={true}
                      progressPending={loading}
                      progressComponent={<Loader />}
                      // onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      highlightOnHover={true}
                      className="DataTable TableShadow"
                    />
                  </div>
                  </div>
        {/* Delete Modal Code */}
        {showDelete && (
          <DeleteDeveloper
            show={showDelete}
            onCancel={() => setShowDelete(false)}
            onDelete={deleteRecord}
            text={"Connection"}
          />
        )}

        {/* Remark Modal Code */}
        {showRemarksModal && (
          <ChatModal
            show={showRemarksModal}
            userId={showRemarkID}
            remarkData={remarkData}
            onPostRemark={postRemark}
            onClose={() => setShowRemarksModal(false)}
          />
        )}
        {/* Follow up Modal Code */}
            </div>
        </BackgroundImage>
      </div>
    </>
  );
};

export default Connection;
