import React from 'react'

const MenuButton = ({ btnIcon, btnText, active , onClick }) => {
    return (
        <div
            onClick={onClick}
            className={`flex justify-start items-center py-3 px-8 my-4 gap-2 rounded-l-2xl max-w-[180px] w-full cursor-pointer ${active ? 'bg-warnOrange' : ''}`}>
            <img src={btnIcon} alt='icon' width={22} />
            <button className='text-white font-bold'>
                {btnText}
            </button>
        </div>
    )
}

export default MenuButton