import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BiSolidEditAlt } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import LongMenu from "../../components/Actions";
import BackButton from "../../components/BackButton/BackButton";
import BackgroundImage from "../../components/BackgroundImage/backgroundImage";
import Button from "../../components/Button/Button";
import Navbar from "../../components/Header/Navbar/Navbar";
import Sidebar from "../../components/Header/Navbar/Sidebar";
import Loader from "../../components/Loader/Loader";
import Title from "../../components/Title/Title";
import { ErrorToast } from "../../components/Toast/ToastCom";
import {
  DeleteApplicantData,
  PostAllApplicantsData,
} from "../../services/PostService";
import { formateCurrency } from "../../utils/common/Common";
import DeleteDeveloper from "../Developers/DeleteDeveloper";
import "../Developers/developer.css";

const Developer = () => {
  const navigate = useNavigate();

  const [page] = useState(1);
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  // const [totalRows, setTotalRows] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [deleteId, setDeleteId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSearchbar, setIsOpenSearchbar] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setIsOpenSearchbar(isOpenSearchbar ? !isOpenSearchbar : isOpenSearchbar);
  };

  const toggleSearchbar = () => {
    setIsOpenSearchbar(!isOpenSearchbar);
    setIsOpen(isOpen ? !isOpen : isOpen);
  };

  const getApplicants = async (pageNo, paginationNo) => {
    try {
      setLoading(true);
      const res = await PostAllApplicantsData(pageNo, paginationNo);
      console.log("varni----------->",res?.data?.Status)
      if(res?.data?.Status === 200|| res?.data?.Status === 201){
        setDisabled(false);
      }else{
        setDisabled(true);
      }
      const devData = res?.data?.Data?.applicantsWithResume;
      setApplicants(devData);
      // setTotalRows(res?.data?.Data?.TotalApplicant);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteRecord = async () => {
    try {
      const url = await DeleteApplicantData(deleteId);
      if (url?.data?.Status === 200) {
        getApplicants();
        setShowDelete(false);
      } else {
        ErrorToast("Error Occurred");
      }
    } catch (e) {
      console.error("Error is ", e);
    }
  };

  const handleEdit = (data) => {
    navigate(`/editProgrammer/${data._id}`);
  };

  const handleView = (data) => {
    navigate(`/programmers/${data._id}`);
  };

  const openDeleteModal = (data) => {
    setShowDelete(true);
    setDeleteId(data._id);
  };

  const options = [
    { action: "View", com: <BsEye />, selectionFunc: handleView },
    { action: "Edit", com: <BiSolidEditAlt />, selectionFunc: handleEdit },
    { action: "Delete", com: <MdDelete />, selectionFunc: openDeleteModal },
  ];

  const columns = [
    {
      name: "No.",
      selector: (row, index) => {
        const realIndex = index + 1 + (page - 1) * paginationPerPage;
        return <span>{realIndex}.</span>;
      },
      width: "3%",
    },
    {
      name: "Joining Date",
      selector: (row) => moment(row.createdAt).format("DD/MM/YY") || "---",
      // width: "6%",
    },
    {
      name: "Recruiter",
      selector: (row) => row.recruiterId?.name || "---",
      // width: "6%",
    },
    {
      name: "Candidate",
      // width: "6%",
      selector: (row) =>
        (row.applicantName === "" ? "-" : row.applicantName) || "---",
    },

    {
      name: "Contact",
      selector: (row) =>
        row.contactNumber === "" ? (
          "---"("-")
        ) : (
          <span
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `tel:${row.contactNumber}`;
            }}
            className="cursor-pointer"
          >
            {row.contactNumber}
          </span>
        ),
      // width: "6%",
    },
    {
      name: "Email",
      width: "9%",
      selector: (row) =>
        row.email === "" ? (
          "---"("-")
        ) : (
          <span
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `mailto:${row.email}`;
            }}
            className="cursor-pointer"
          >
            {row.email}
          </span>
        ),
    },
    {
      name: "Total Experience",
      // width: "6%",
      selector: (row) =>
        row.totalExperience ? row.totalExperience + " year" : "---",
    },
    {
      name: "Experience",
      // width: "5%",
      selector: (row) =>
        row.relevantExperience ? row.relevantExperience + " year" : "---" ,
    },
    {
      name: "Current CTC",
      // width: "6%",
      selector: (row) =>
        row.currentCTC ? formateCurrency(row.currentCTC) : "---",
    },
    {
      name: "Expected CTC",
      // width: "6%",
      selector: (row) =>
        row.expectedCTC ? formateCurrency(row.expectedCTC) : "---",
    },
    {
      name: "Notice Period",
      // width: "6%",
      selector: (row) =>
        row.noticePeriod ? row.noticePeriod + " months" : "---",
    },
    {
      name: "Current Location",
      // width: "6%",
      selector: (row) =>
        row.currentLocation ? row.currentLocation : "---",
    },
    {
      name: "Prefer Location",
      // width: "5%",
      selector: (row) =>
        row.preferedLocation ? row.preferedLocation : "---",
    },
    {
      name: "Company",
      // width: "6%",
      selector: (row) =>
        row.companyName ? row.companyName : "---",
    },
    {
      name: "Skills",
      // width: "5%",
      selector: (row) => 
        row.mainSkill ? row.mainSkill : "---",
    },
    {
      name: "Other Skills",
      // width: "8%",
      selector: (row) =>
        row.otherSkill ? row.otherSkill : "---",
    },
    {
      width: "4%",
      cell: (row) => (
        <div>
          <LongMenu
            options={options}
            rowData={row}
          // editAction={handleEdit}
          // viewAction={handleView}
          // deleteAction={openDeleteModal}
          />
        </div>
      ),
    },
  ];

  // const paginationOptions = {
  //   rowsPerPageText: "Rows per page:",
  //   rangeSeparatorText: "of",
  //   selectAllRowsItem: true,
  // };

  const customStyles = {
    headRow: {
      style: {
        fontSize: "12px",
        borderBottom: "2px solid var(--LIGHTORANGE) !important",
        color: "var(--LIGHTORANGE)",
        fontWeight: "700",
        "@media (max-width: 425px)": {
          fontSize: "0.8rem",
        },
        "@media (max-width: 768px)": {
          fontSize: "14px",
        },
        "@media (min-width: 768px) and (max-width: 1024px)": {
          fontSize: "16px",
        },
        "@media (min-width: 1440px)": {
          fontSize: "18px",
        },
        "@media (min-width: 2560px)": {
          fontSize: "20px",
        },
      },
    },
    headCells: {
      style: {
        padding: "10px",
        borderBottom: "1px solid var(--LIGHTORANGE)",
        "@media (min-width: 2560px)": {
          padding: "20px",
        },
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid var(--LIGHTORANGE) !important",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
        textAlign: "center",
        fontWeight: "600",
        "@media (max-width: 425px)": {
          fontSize: "0.8rem !important",
        },
        "@media (max-width: 768px)": {
          fontSize: "1rem",
        },
        "@media (max-width: 1024px)": {
          fontSize: "1rem",
        },
        "@media (min-width: 1440px)": {
          fontSize: "14px",
        },
        "@media (min-width: 2560px)": {
          fontSize: "1.5rem",
        },
      },
    },
    // pagination: {
    //   style: {
    //     fontSize: "1.2rem",
    //     maxWidth: "100%",
    //     margin: "0 auto",
    //   },
    // },
    table: {
      backgroundColor: "var(--RED) !important",
    },
  };

  const handlePageChange = (e) => {
    getApplicants(e, paginationPerPage);
  };

  const handlePerRowsChange = async (rowsPerPage, page) => {
    setPaginationPerPage(rowsPerPage);
  };

  const exportCSVData = () => {
    const link = document.createElement("a");
    link.setAttribute("href", "https://api.ats.prydan.com/api/export-file");
    link.setAttribute("download", "exported_data.csv");
    link.click();
  };

  useEffect(() => {
    // console.log(apiCalled)
    getApplicants(page, paginationPerPage);
  }, [page, paginationPerPage]);

  return (
    <>
      <Navbar
        isOpenSearchbar={isOpenSearchbar}
        toggleSidebar={toggleSidebar}
        toggleSearchbar={toggleSearchbar}
      />
      <div className="flex w-full mt-[75px]">
        <Sidebar isOpen={isOpen} />
        <BackgroundImage className={'w-full'}>
          <div className="w-full p-6 md:pl-64 md:pr-15 min-h-screen md:block">
            <div className="max-md:flex-col max-lg:flex-col justify-evenly max-md:mx-auto h-full">
              <div className="max-md:flex-col max-lg:flex-col justify-evenly max-md:mx-auto h-full">
                <div className="flex flex-row max-md:flex-col justify-between items-center w-full">
                  <div className="cursor-pointer max-md:px-0">
                    <BackButton onClick={"/dashboard"} />
                  </div>
                  <div>
                    <Title text={"All Applicants"} />
                  </div>
                  {/* search code for future use  */}
                  {/* <div className=" py-3 px-3 max-md:px-0">
                          <div className="gap-3 flex">
                            <input
                              className="md:px-3 md:py-2 border-2 rounded  text-black border-black shadow-sm focus:outline-none focus:border-black focus:ring-black focus:ring-1"
                              type="text"
                            />
                            <button className="font-extrabold  text-black text-xl">
                              Search
                            </button>
                          </div>
                        </div> */}
                  <div className="flex flex-wrap items-center justify-between py-3 max-md:flex-nowrap">
                    <div className="pr-2 pb-2 max-md:w-full md:pl-4">
                      <Button
                        text="ADD"
                        onClick={() => navigate("/addProgrammer")}
                        className="bg-warnOrange text-white uppercase font-extrabold py-2 lg:py-3 px-4 lg:px-6 text-lg lg:text-xl rounded-md"
                      />
                    </div>
                    <div className="pb-2 max-md:w-full">
                      <button
                        onClick={exportCSVData}
                        className={` ${!disabled ? "bg-warnOrange" : "bg-gray-200"} font-extrabold uppercase py-2 lg:py-3 px-4 lg:px-6 max-md:px-3 text-lg lg:text-xl rounded-md text-white`}
                        disabled={disabled}
                      >
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="border-warnOrange py-3 lg:py-5 w-full border-t-2" />
                <div>
                  <DataTable
                    columns={columns}
                    data={applicants}
                    // pagination
                    // paginationServer
                    // paginationComponentOptions={{
                    //   ...paginationOptions,
                    //   totalRows: totalRows,
                    // }}
                    customStyles={customStyles}
                    fixedHeader
                    responsive={true}
                    progressPending={loading}
                    progressComponent={<Loader />}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover={true}
                    className="DataTable TableShadow"
                  />
                </div>
              </div>
              {/* Delete Modal Code */}
              {showDelete && (
                <DeleteDeveloper
                  show={showDelete}
                  onCancel={() => setShowDelete(false)}
                  onDelete={deleteRecord}
                  text={"Applicant"}
                />
              )}
            </div>
          </div>
        </BackgroundImage>
      </div>
    </>
  );
};

export default Developer;
