import React from "react";
import RocketGif from "../../components/Login/RocketGif";
import Form from "../../components/Login/Form";
import EditLabel from "../../components/Login/Label";
import LogoATS from "../../components/Login/logoATS";

const Login = () => {
  return (
    <div className="bg-skyBlue flex items-center justify-center relative lg:h-screen md:h-screen">
      <div className="grid-cols-1 md:grid-cols-2 md:w-4/5 md:h-[80vh] xl:h-[80vh] grid bg-white w-full h-full mx-auto shadow-md overflow-hidden rounded-md">
        <RocketGif />
        <div className="relative">
          <EditLabel label={"Welcome Back"} />
          <div className=" py-12 xl:p-16 md:px-0 flex flex-col justify-center xl:justify-evenly max-md:mt-5 items-center h-full">
            <LogoATS />
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
