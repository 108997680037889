export const BASE_URL = "https://api.ats.prydan.com/api";

// export const BASE_URL = "https://ats.development.api.prydan.com/api"
// export const BASE_URL = "https://ats-backend-theta.vercel.app/api"
// export const BASE_URL = "http://192.168.1.12:8001/api"
// export const BASE_URL = "http://localhost:8001/api"
// export const BASE_URL = "https://hskncvbs-8001.inc1.devtunnels.ms/api"

/*
    "Make new endpoint names using only capital letters for the type and name."
    "Format"
        example: export const ANY_NAME_URL = BASE_URL + "/endpoints/"
*/

// AUTHENTICATION ALL API END POINTS
export const REGISTRATION_URL = BASE_URL + "/register";
export const LOGIN_URL = BASE_URL + "/login";
export const RESET_PASSWORD_URL = BASE_URL + "/reset-password";
export const FORGOT_PASSWORD = BASE_URL + "/forgot-password";
export const OTP_VERIFICATION = BASE_URL + "/otp-verification";
export const PASSWORD_UPDATE = BASE_URL + "/password-update";

// USERS API END POINTS
// export const USERS_URL = BASE_URL + "/users/";

// DASHBOARD API END POINTS
export const RECRUITER_URL = BASE_URL + "/recruiter/";
export const RECRUITER = BASE_URL + "/get-recruiter/";

//ADD RECRUITER API END POINTS
export const ADDRECRUITER_URL = BASE_URL + "/add-recruiter";

//APPLICANTS API END POINTS
export const APPLICANTS_URL = BASE_URL + "/applicant/";
export const ADDAPPLICANT = BASE_URL + "/add-applicant";
export const APPLICANT_DATA_URL = BASE_URL + "/get-applicant";

//SEARCH API END POINTS

export const APPLICANT_SORT = BASE_URL + "/applicant-search";

//CONNECTIONS API END POINTS
export const CONNECTION_URL = BASE_URL + "/connection/";
export const GET_CONNECTION_URL = BASE_URL + "/get-connection/";
// "/get-marketing/";

export const CONNECTION = BASE_URL + "/add-connection";
export const PATCH_CONNECTION = BASE_URL + "/connection/";

//CONNECTION REMARK ENDPOINTS
export const CONNECTION_REMARK = BASE_URL + "/connection-remark/";
