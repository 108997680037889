import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../components/Title/Title";
import {
  validEmail,
  validName,
  validatePassword,
} from "../../utils/common/Common";
import { AddRecruiter } from "../../services/PostService";
import Button from "../../components/Button/Button";
import "../../pages/Forms/AddDeveloperForm.css";
import Swal from "sweetalert2";
import { ErrorToast, SuccessToast } from "../../components/Toast/ToastCom";

const Recruiterform = () => {
  const navigateForm = useNavigate();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  const validation = async (from) => {
    if (from === 1) {
      const errors = {
        fullName:
          formData.fullName === ""
            ? "Please Enter Recruiter Name"
            : !validName(formData.fullName)
            ? "Recruiter Name must be alphabatic"
            : "",
        email:
          formData.email === ""
            ? "Please Enter Email"
            : !validEmail(formData.email)
            ? "Please Enter Valid Email"
            : "",
        password:
          formData.password === ""
            ? "Please Enter Password"
            : !validatePassword(formData.password)
            ? "Please Enter Valid Password (Test@123)"
            : "",
      };

      setValidationErrors(errors);

      if (Object.values(errors).some((error) => error)) {
        return;
      }

      try {
        await AddRecruiter(formData).then((res) => {
          if (res.data.Status === 201) {
            SuccessToast(res?.data?.Message);
          } else {
            ErrorToast(res?.data?.Message);

          }
        });
      } catch {
        console.log("something wrong with it");
      }
      navigateForm("/recruiters");
    } else {
      const errors = {};

      if (formData.fullName !== "" && !validName(formData.fullName)) {
        errors.fullName = "Recruiter Name must be alphabetic";
      } else {
        errors.fullName = "";
      }

      if (formData.email !== "" && !validEmail(formData.email)) {
        errors.email = "Please Enter Valid Email";
      } else {
        errors.email = "";
      }

      if (formData.password !== "" && !validatePassword(formData.password)) {
        errors.password = "Please Enter Valid Password (Test@123)";
      } else {
        errors.password = "";
      }

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        ...errors,
      }));
    }
  };

  useEffect(() => {
    validation(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleInputChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
    validation(fieldName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validation(1);
  };

  return (
    <div className="flex justify-center items-center w-full h-screen  bg-skyBlue">
        <div className="relative p-4 w-full max-w-2xl">
          {/* <div className="relative px-10 py-8"> */}
          <div className="flex items-center justify-between md:p-1 border-b-4 border-warnOrange ">
            <Title
              text={"Add Recruiters"}
              className="md:text-4xl 2xl:text-5xl"
            />
          </div>
          <form
            onSubmit={(event) => handleSubmit(event)}
            className=" bg-lightGry border-2 mt-8 border-warnOrange shadowform"
          >
            <div className="p-4 md:p-5 space-y-6">
              <div className="px-5 flex flex-col sm:flex-row md:flex-row lg:flex-row">
                <div className=" w-full sm:w-1/3 md:w-1/3 lg:w-1/3">
                  <label className="mb-2 self-center font-semibold ">
                    Recruiter Name:
                  </label>
                </div>
                <div className="w-10/12 sm:w-2/3 md:w-2/3 lg:w-2/3">
                  <input
                    type="text"
                    className="rounded-md border-2 text-warnOrange border-black font-semibold mx-2 p-1 mb-1 self-center bg-transparent w-full sm:w-5/6 md:w-5/6 lg:w-5/6"
                    name="fullName"
                    value={formData.fullName}
                    onChange={(e) =>
                      handleInputChange("fullName", e.target.value)
                    }
                  />
                  <div className="ml-2">
                    {validationErrors.fullName && (
                      <p className="text-warnOrange font-semibold">
                        {validationErrors.fullName}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="px-5 flex flex-col sm:flex-row  md:flex-row lg:flex-row">
                <div className="w-full sm:w-1/3 md:w-1/3 lg:w-1/3">
                  <label className=" self-center font-semibold">
                    Email Id:
                  </label>
                </div>
                <div className="w-10/12 sm:w-2/3 md:w-2/3 lg:w-2/3">
                  <input
                    type="text"
                    className="rounded-md border-2 border-black text-warnOrange font-semibold mx-2 p-1 mb-1 self-center bg-transparent w-full sm:w-5/6 md:w-5/6 lg:w-5/6"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                  <div className="ml-2">
                    {validationErrors.email && (
                      <p className="text-warnOrange font-semibold">
                        {validationErrors.email}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="px-5 flex flex-col sm:flex-row  md:flex-row lg:flex-row">
                <div className="w-full sm:w-1/3 md:w-1/3 lg:w-1/3">
                  <label className="elf-center font-semibold">password:</label>
                </div>
                <div className="w-10/12 sm:w-2/3 md:w-2/3 lg:w-2/3">
                  <input
                    type="password"
                    className="rounded-md border-2 border-black text-warnOrange font-semibold mx-2 p-1 mb-1 self-center bg-transparent w-full sm:w-5/6 md:w-5/6 lg:w-5/6"
                    name="password"
                    value={formData.password}
                    onChange={(e) =>
                      handleInputChange("password", e.target.value)
                    }
                  />
                  <div className="ml-2">
                    {validationErrors.password && (
                      <p className="text-warnOrange font-semibold">
                        {validationErrors.password}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex items-center justify-start gap-4 p-3 md:py-5 px-7 max-[426px]:flex-col">
              <Button
                text={"close"}
                type={"button"}
                className="bg-gray font-semibold text-white bg-warnOrange rounded-lg border border-warnOrange text-sm px-5 py-2.5 hover:text-gray-900 focus:z-10"
                onClick={() => navigateForm("/recruiters")}
              />
              <Button
                text={"Submit"}
                type={"submit"}
                className={
                  "text-white bg-warnOrange font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                }
              />
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
  );
};

export default Recruiterform;
