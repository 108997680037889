import React from "react";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../../utils/images/Images";
import { Link } from "react-router-dom";

const SocialMedia = ({ Data }) => {
  if (!Data) {
    return null;
  }

  const socialMediaList = [
    {
      icons: LinkedinIcon,
      link: `${Data?.linkedIn}`,
    },
    {
      icons: TwitterIcon,
      link: `${Data?.twitter}`,
    },
    {
      icons: FacebookIcon,
      link: `${Data?.facebook}`,
    },
    {
      icons: InstagramIcon,
      link: `${Data?.instagram}`,
    },
  ];

  return (
    <div className="flex justify-center items-center gap-4 my-3">
      {socialMediaList?.map((item, index) => (
        <div key={index} className="py-1 cursor-pointer">
          <Link to={item?.link} target="_blank">
            <img src={item?.icons} alt={"Not Found"} />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default SocialMedia;
