import React from "react";
import { useParams } from "react-router-dom";
import ViewData from "../../components/FormItems/ViewData";
import { GetSingleConnection } from "../../services/GetService";

const ViewConnection = () => {
  const connectionId = useParams();

  const fieldName = {
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    mobileNo: "Mobile No:",
    email: "Email",
    linkedin: "linkedin",
    instagram: "Instagram",
    facebook: "Facebook",
    twitter: "Twitter",
    company: "Company",
    designation: "Designation",
    location: "Location",
    city: "City",
    state: "State",
    nationality: "Nationality",
    age: "Age",
    hobby: "Hobby",
    notePersonal: "Note Personal",
    photo: "Photo",
    referredBy: "Referred By",
    dateCreated: "Date Created",
  };

  const getConnection = (id) => {
    if (connectionId !== undefined) {
      return GetSingleConnection(id);
    }
  };

  return (
    <div>
      <ViewData
        stateObj={fieldName}
        userId={connectionId.id}
        getAction={getConnection}
        name={"Connection"}
      />
    </div>
  );
};

export default ViewConnection;
