import React from 'react'
import CommingComp from '../../components/Coming Soon/CommingComp'

const Position = () => {
  return (
    <div>
      <CommingComp/>
    </div>
  )
}

export default Position
