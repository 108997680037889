import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { WelcomeBackground1 } from "../../../utils/images/Images";
import Button from "../../Button/Button";
import "./UserDataform.css";
import Title from "../../Title/Title";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateUser } from "../../../services/PostService";
import { DashboardID } from "../../../redux/Selector/Selector";
import { checkEmpty } from "../../../utils/common/Common";
import { Demo } from "./ProfilePhoto";

const ProfileForm = ({ user }) => {
  const initilValues = {
    linkedIn: user?.linkedIn || "",
    instagram: user?.instagram || "",
    twitter: user?.twitter || "",
    facebook: user?.facebook || "",
    workplace: user?.workplace || "",
    descriptionUser: user?.descriptionUser || "",
    image: "",
  };

  const navigateForm = useNavigate();
  const Login_ID = useSelector(DashboardID.getdashboardId());
  const [formData, setFormData] = useState(initilValues);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    checkEmpty(formData) ? setDisable(false) : setDisable(true);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formDataObject = new FormData();
      for (const key in formData) {
        formDataObject.append(key, formData[key]);
      }

      const res = await UpdateUser(Login_ID, formDataObject);

      if (res?.status === 200) {
        navigateForm("/dashboard");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFile = async (croppedImageData) => {
    if (croppedImageData) {
      setFormData((prev) => ({ ...prev, image: croppedImageData }));
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (user) {
      setFormData({
        linkedIn: user?.linkedIn || "",
        instagram: user?.instagram || "",
        workplace: user?.workplace || "",
        descriptionUser: user?.descriptionUser || "",
      });
    }
  }, [user]);

  return (
    <div
      className="box"
      style={{
        backgroundImage: `url(${WelcomeBackground1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="border-b-4 border-warnOrange mx-6">
        <Title
          text={"Add User Detail"}
          className="py-4 md:text-2xl 2xl:text-5xl text-center"
        />
      </div>
      <form onSubmit={handleSubmit} className="flex justify-center mt-5">
        <div className="max-w-2xl w-full">
          <div className="border-0 px-3 py-1.5 max-sm:px-2 alg-items-center">
            <div className="form bg-lightGry shadowform rounded-md p-3">
              <div className="w-full sm:w-1/2 m-0 sm:mx-auto">
                <label className="label fw-bold">
                  <div className="flex">
                    LinkedIn:<strong className="text-red-600">*</strong>
                  </div>
                  <input
                    className="input"
                    type="email"
                    name="linkedIn"
                    onChange={handleInputChange}
                  />
                </label>
                <label className="label fw-bold">
                  <div className="flex">
                    Instagram:<strong className="text-red-600">*</strong>
                  </div>
                  <input
                    className="input"
                    type="text"
                    name="instagram"
                    onChange={handleInputChange}
                  />
                </label>
                <label className="label fw-bold">
                  <div className="flex">
                    Twitter:<strong className="text-red-600">*</strong>
                  </div>
                  <input
                    className="input"
                    type="text"
                    name="twitter"
                    onChange={handleInputChange}
                  />
                </label>
                <label className="label fw-bold">
                  <div className="flex">
                    Facebook:<strong className="text-red-600">*</strong>
                  </div>
                  <input
                    className="input"
                    type="text"
                    name="facebook"
                    onChange={handleInputChange}
                  />
                </label>
                <label className="label fw-bold">
                  <div className="flex">
                    Workplace:<strong className="text-red-600">*</strong>
                  </div>
                  <input
                    className="input"
                    type="text"
                    name="workplace"
                    onChange={handleInputChange}
                  />
                </label>
                <label className="label fw-bold mb-0">
                  <div className="flex">
                    Description:<strong className="text-red-600">*</strong>
                  </div>
                  <input
                    className="input mb-0"
                    type="text"
                    name="descriptionUser"
                    onChange={handleInputChange}
                  />
                </label>
                <Demo fileAction={handleFile} />
                <div className="text-red-600 font-bold w-full pb-4">
                  * fields are required.
                </div>
                <div className="w-full flex justify-center">
                  <Button
                    onClick={handleSubmit}
                    text={"Save"}
                    type="submit"
                    style={{ backgroundColor: `${disable ? "gray" : ""}` }}
                    className={`button`}
                    disabled={disable}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.any,
};
export default ProfileForm;
