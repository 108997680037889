import React, { useState } from "react";
import Navbar from "../../components/Header/Navbar/Navbar";
import Sidebar from "../../components/Header/Navbar/Sidebar";
import Profile from "../../components/Dashboard/Profile/Profile";
import Tabs from "../../components/Dashboard/Tabs/Tabs";
import Calendar from "../../components/Calendar/Calendar";
import BackgroundImage from "../../components/BackgroundImage/backgroundImage";

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSearchbar, setIsOpenSearchbar] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setIsOpenSearchbar(isOpenSearchbar ? !isOpenSearchbar : isOpenSearchbar);
  };

  const toggleSearchbar = () => {
    setIsOpenSearchbar(!isOpenSearchbar);
    setIsOpen(isOpen ? !isOpen : isOpen);
  };

  return (
    <>
      <Navbar
        isOpenSearchbar={isOpenSearchbar}
        toggleSidebar={toggleSidebar}
        toggleSearchbar={toggleSearchbar}
      />
      <div className="flex w-full mt-[75px]">
        <Sidebar isOpen={isOpen} />
        {/* <div
          className="w-full p-6 md:pl-64 md:pr-20 min-h-screen md:block"
          style={{
            backgroundImage: `url(${WelcomeBackground1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        > */}
        <BackgroundImage className={'w-full p-6 md:pl-64 md:pr-20 min-h-screen md:block'}>
          <div className="flex max-md:flex-col max-lg:flex-col justify-evenly max-md:mx-auto h-full">
            <div
              className="flex flex-col min-h-screen"
              style={{ width: "-webkit-fill-available" }}
            >
              <div className="mb-5 max-md:min-w-fit">
                <Profile />
              </div>
              <div className="mb-5">
                <Tabs />
              </div>
            </div>
            <div className="ml-5 sm:grid-cols-10 max-h-screen max-md:mt-2 max-md:mx-auto">
              <Calendar />
            </div>
          </div>
        </BackgroundImage>
        {/* </div> */}
      </div>
    </>
  );
};

export default Dashboard;
